import React from "react";
import { connect } from "react-redux";
import { getEntityItems, readEntities, readEntity, partialEntity, deleteEntity, createEntity } from "../../redux/api";
import { loadSession, setOrderCommentsSeleted, setOrderSeleted } from "../../redux/reducers/global-catalog.reducer";
import ConfirmDialog from "../../shared/dialog/confirm-dialog";
import MenuAlertsDetailsCards from "./details-cards";
import { AlertsNoFound } from "./styled";
import GenericDialog from "../../shared/dialog/generic-modal";

const TODAY = new Date(
  new Date().getFullYear(),
  new Date().getMonth(),
  new Date().getDate()
);

const YESTERDAY = new Date(
  new Date().getFullYear(),
  new Date().getMonth(),
  (new Date().getDate()) - 1 
);

class MenuAlertsDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cards: {},

      showReadAlertModal: false,
      showUnreadAlertModal: false,
      showDeleteAlertModal: false,
      showUnsubscribeAlertModal: false,
      showUnreadNotificationModal: false,
      showReadNotificationModal: false,
      showDeleteNotificationModal: false,
      showCouponNotificationModal: false,

      selectedAlertId: 0,
      couponId: 0,

      messageInfo: null
    };
  }

  componentDidMount() {
    this.loadAlerts();
  }

  componentDidUpdate(prevProps) {
    const { currentOpc } = this.props;
    if (prevProps.currentOpc !== currentOpc) {
      if (currentOpc !== "alerts") {
        this.setState({
          cards: {
            today: [],
            yesterday: [],
            daysago: [
              // {
              //   id: 1,
              //   type: 4,
              //   title: "Orden de Compra",
              //   time: "22:17",
              //   description:
              //     "El cliente 335409223 creo un nuevo pedido para la planta toluca…",
              // },
            ],
          },
        });
      }
    }
  }

  loadAlerts = () => {
    let action = "read_notifications"
    this.props.loadAlertDetail(
      this.props.companyId,
      this.props.userData,
      action
    );

    this.props.loadNotifications({
      company_id: this.props.companyId,
      user_id: this.props.userId
    });
  }

  //Actions
  markReadNotification = () => {
    this.props.editNotification({
      action: "mark_read",
      company_id: this.props.companyId,
      user_id: this.props.userId,
      notification_id: this.state.selectedAlertId,
      attended: true
    }, {
      onSuccess: () => {
        if (this.props.isModalOpen) this.props.isModalOpen(false);
        this.setState({showReadNotificationModal: false, selectedAlertId: 0});
        this.loadAlerts();

        const event = new CustomEvent('user-update', { detail: {notification_id: 45} });
        document.dispatchEvent(event);

        if (this.props.closePanel) this.props.closePanel();
      }
    })
  }

  redeemCoupon = () => {

    return;

    this.props.redeemCoupon({
      action: "redeem",
      company_id: this.props.companyId,
      notification_id: this.state.selectedAlertId,
      coupon_id: this.state.couponId
    }, {
      onSuccess: () => {
        if (this.props.isModalOpen) this.props.isModalOpen(false);
        this.setState({showCouponNotificationModal: false, selectedAlertId: 0, coupon_id: 0});
        this.loadAlerts();

        const event = new CustomEvent('user-update', { detail: {notification_id: 45} });
        document.dispatchEvent(event);
      },
      onError: (e) => {
        this.setState({showCouponNotificationModal: false, messageInfo: {title: 'Error', message: e.response?.data?.error || 'Ocurrió un error, intente de nuevo'}})
      }
    });

  }

  markUnreadNotification = () => {
    this.props.editNotification({
      action: "mark_read",
      company_id: this.props.companyId,
      user_id: this.props.userId,
      notification_id: this.state.selectedAlertId,
      attended: false
    }, {
      onSuccess: () => {
        if (this.props.isModalOpen) this.props.isModalOpen(false);
        this.setState({showUnreadNotificationModal: false, selectedAlertId: 0});
        this.loadAlerts();

        const event = new CustomEvent('user-update', { detail: {notification_id: 45} });
        document.dispatchEvent(event);
      }
    })
  }

  deleteNotification = () => {
    this.props.deleteNotification({
      action: "delete_notitication",
      company_id: this.props.companyId,
      user_id: this.props.userId,
      notification_id: this.state.selectedAlertId,
    }, {
      onSuccess: () => {
        if (this.props.isModalOpen) this.props.isModalOpen(false);
        this.setState({showDeleteNotificationModal: false, selectedAlertId: 0});
        this.loadAlerts();
        const event = new CustomEvent('user-update', { detail: {notification_id: 45} });
        document.dispatchEvent(event);
      }
    })
  }

  markRead = () => {
    this.props.attendAlert({
      company_id: this.props.companyId,
      company_user: this.props.userData,
      alert_id: this.state.selectedAlertId,
      attended: true
    }, {
      onSuccess: () => {
        if (this.props.isModalOpen) this.props.isModalOpen(false);
        this.setState({showReadAlertModal: false, selectedAlertId: 0});
        this.loadAlerts();
      }
    })
  }

  markUnread = () => {
    this.props.attendAlert({
      company_id: this.props.companyId,
      company_user: this.props.userData,
      alert_id: this.state.selectedAlertId,
      attended: false
    }, {
      onSuccess: () => {
        if (this.props.isModalOpen) this.props.isModalOpen(false);
        this.setState({showUnreadAlertModal: false, selectedAlertId: 0});
        this.loadAlerts();
      }
    })
  }

  deleteAlert = () => {
    this.props.deleteAlert({
      company_id: this.props.companyId,
      company_user: this.props.userData,
      alert_id: this.state.selectedAlertId
    }, {
      onSuccess: () => {
        if (this.props.isModalOpen) this.props.isModalOpen(false);
        this.setState({showDeleteAlertModal: false, selectedAlertId: 0});
        this.loadAlerts();
      }
    })
  }

  unsubscribeAlert = () => {
    this.props.unsubscribeAlert({
      company_id: this.props.companyId,
      company_user: this.props.userData,
      alert_id: this.state.selectedAlertId
    }, {
      onSuccess: () => {
        if (this.props.isModalOpen) this.props.isModalOpen(false);
        this.setState({showUnsubscribeAlertModal: false, selectedAlertId: 0});
        this.loadAlerts();
      }
    })
  }

  buildActions = (aD) => {

    let id = aD.id;
    let attended = aD.attended;
    let isNotification = aD.is_notification;

    if (isNotification) {

      let actions = [
        {
          description: attended ? "Marcar como no leída" : "Marcar como leída",
          action: () => {
            if (this.props.isModalOpen) this.props.isModalOpen(true);
            this.setState({[attended ? "showUnreadNotificationModal" : "showReadNotificationModal"]: true, selectedAlertId: id})
          }
        },
        {
          description: "Eliminar notificación",
          action: () => {
            if (this.props.isModalOpen) this.props.isModalOpen(true);
            this.setState({showDeleteNotificationModal: true, selectedAlertId: id})
          }
        },
      ];

      // let is_coupon = aD.type_notification === 47;

      // if (is_coupon) {

      //   let coupon_status = aD.extra_data?.status?.id || 2;

      //   if (coupon_status === 1) {
      //     actions.unshift({
      //       description: "Redimir",
      //       action: () => {
      //         if (this.props.isModalOpen) this.props.isModalOpen(true);
      //         this.setState({showCouponNotificationModal: true, selectedAlertId: id, couponId: aD.extra_data?.id ?? 0})
      //       }
      //     });
      //   }

      // }

      return actions;
    } else {
      return(
        [
          {
            description: attended ? "Marcar como no leída" : "Marcar como leída",
            action: () => {
              if (this.props.isModalOpen) this.props.isModalOpen(true);
              this.setState({[attended ? "showUnreadAlertModal" : "showReadAlertModal"]: true, selectedAlertId: id})
            }
          },
          {
            description: "Eliminar alerta",
            action: () => {
              if (this.props.isModalOpen) this.props.isModalOpen(true);
              this.setState({showDeleteAlertModal: true, selectedAlertId: id})
            }
          },
          // {
          //   description: "De-suscribir de la alerta",
          //   action: () => {this.setState({showUnsubscribeAlertModal: true, selectedAlertId: id})}
          // }
        ]
      );
    }
  }

  getNotificationTitle = (aD) => { 
    if (aD.is_notification) {
      return aD.type_notification === 47 ? 'Cupón' : 'Notificación';
    }
    return 'Alerta';
  }

  getIcon = (aD) => {
    if (aD.is_notification) {
      return aD.type_notification === 47 ? 4 : 2;
    }
    return 1;
  }

  render() {
    let today = []; 
    let yesterday = [];
    let daysago = [];
    if(this.props.currentOpc === "alerts"){
      today = this.props.alert_details.filter(aD => {
        let currentDate = new Date(aD.created);
        return currentDate.getTime() >= TODAY.getTime()
      }).map(aD => {
        let alertDate = new Date(aD.created);
        return {
        id: aD.id,
        type: this.getIcon(aD),
        is_notification: aD.is_notification,
        notification_data: aD.data,
        title: this.getNotificationTitle(aD),
        time: ''.concat(alertDate.getHours(), ':', alertDate.getMinutes()),
        description: aD.message,
        level: aD.level,
        object: aD.object,
        facility: aD.facility,
        alert: aD.alert,
        notification: aD.notification,
        alert_date: alertDate,
        attended: aD.attended,
        actions: this.buildActions(aD),
        type_notification: aD.type_notification,
        extra_data: aD.extra_data,
        markReadNotification: () => {
          this.setState({selectedAlertId: aD.id}, () => {
            this.markReadNotification()
          })
        },
        deleteNotification: () => {
          this.setState({selectedAlertId: aD.id}, () => {
            this.deleteNotification()
          })
        }
      }});
      yesterday = this.props.alert_details.filter(aD => {
        let currentDate = new Date(aD.created);
        return currentDate.getTime() >= YESTERDAY.getTime() && currentDate.getTime() < TODAY.getTime()
      }).map(aD => {
        let alertDate = new Date(aD.created);
        return {
        id: aD.id,
        type: this.getIcon(aD),
        is_notification: aD.is_notification,
        notification_data: aD.data,
        title: this.getNotificationTitle(aD),
        time: ''.concat(alertDate.getHours(), ':', alertDate.getMinutes()),
        description: aD.message,
        level: aD.level,
        object: aD.object,
        facility: aD.facility,
        alert: aD.alert,
        notification: aD.notification,
        alert_date: alertDate,
        attended: aD.attended,
        actions: this.buildActions(aD),
        type_notification: aD.type_notification,
        extra_data: aD.extra_data,
        markReadNotification: () => {
          this.setState({selectedAlertId: aD.id}, () => {
            this.markReadNotification()
          })
        },
        deleteNotification: () => {
          this.setState({selectedAlertId: aD.id}, () => {
            this.deleteNotification()
          })
        }
      }});
      daysago = this.props.alert_details.filter(aD => {
        let currentDate = new Date(aD.created);
        return currentDate.getTime() < YESTERDAY.getTime()
      }).map(aD => {
        let alertDate = new Date(aD.created);
        return {
        id: aD.id,
        type: this.getIcon(aD),
        is_notification: aD.is_notification,
        notification_data: aD.data,
        title: this.getNotificationTitle(aD),
        time: ''.concat(alertDate.getHours(), ':', alertDate.getMinutes()),
        description: aD.message,
        level: aD.level,
        object: aD.object,
        facility: aD.facility,
        alert: aD.alert,
        notification: aD.notification,
        alert_date: alertDate,
        attended: aD.attended,
        actions: this.buildActions(aD),
        type_notification: aD.type_notification,
        extra_data: aD.extra_data,
        markReadNotification: () => {
          this.setState({selectedAlertId: aD.id}, () => {
            this.markReadNotification()
          })
        },
        deleteNotification: () => {
          this.setState({selectedAlertId: aD.id}, () => {
            this.deleteNotification()
          })
        }
      }});
    } else {
      today = this.state.cards.today;
      yesterday = this.state.cards.yesterday;
      daysago = this.state.cards.daysago;
    }
    
    
    return (
      <React.Fragment>
        <div style={{fontWeight: "bold"}}>Hoy</div> 
        {today && today.length ? (
          (today || []).map((card) => {
            return <MenuAlertsDetailsCards 
              history={this.props.history} 
              card={card} 
              key={`alert-${card.id}`}
              setOrderDetail={ e => {this.props.setOrderDetail(e); this.props.setOrderCommentsSeleted();}}
              errorOpenTrip={() => this.setState({messageInfo: {title: 'Error', message: 'El viaje ya no pertenece a esta compañía'}})}
            />;
          })
        ) : (
          <AlertsNoFound>No Existen alertas</AlertsNoFound>
        )}
        <div style={{fontWeight: "bold"}}>Ayer</div>
        {yesterday && yesterday.length ? (
          (yesterday || []).map((card) => {
            return <MenuAlertsDetailsCards 
              history={this.props.history} 
              card={card} 
              key={`alert-${card.id}`}
              setOrderDetail={ e => {this.props.setOrderDetail(e); this.props.setOrderCommentsSeleted();}}
              errorOpenTrip={() => this.setState({messageInfo: {title: 'Error', message: 'El viaje ya no pertenece a esta compañía'}})}
            />;
          })
        ) : (
          <AlertsNoFound>No Existen alertas</AlertsNoFound>
        )}
        <div style={{fontWeight: "bold"}}>Más antiguas</div>
        {daysago && daysago.length ? (
          (daysago || []).map((card) => { 
            return <MenuAlertsDetailsCards 
              history={this.props.history} 
              card={card} 
              key={`alert-${card.id}`}
              setOrderDetail={ e => {this.props.setOrderDetail(e); this.props.setOrderCommentsSeleted();}} 
              errorOpenTrip={() => this.setState({messageInfo: {title: 'Error', message: 'El viaje ya no pertenece a esta compañía'}})}
            />;
          })
        ) : (
          <AlertsNoFound>No Existen alertas</AlertsNoFound>
        )}
        <GenericDialog
          open={this.state.messageInfo !== null}
          title={this.state.messageInfo?.title || ''}
          message={this.state.messageInfo?.message || ''}
          acceptAction={() => this.setState({messageInfo: null})}
          closeAction={() => this.setState({messageInfo: null})}
        />
        <ConfirmDialog
          open={this.state.showReadNotificationModal}
          title="Marcar como leída"
          message={"¿Estás seguro que deseas marcar esta notificación como leída?"}
          acceptText="Confirmar"
          class="delete-facility"
          showCancel={true}
          cancelText="Cancelar"
          cancelButtonType="secondary"
          closeAction={() => {
            if (this.props.isModalOpen) this.props.isModalOpen(false);
            this.setState({ showReadNotificationModal: false })}
          }
          acceptAction={this.markReadNotification}
        />
        <ConfirmDialog
          open={this.state.showUnreadNotificationModal}
          title="Marcar como no leída"
          message={"¿Estás seguro que deseas marcar esta notificación como no leída?"}
          acceptText="Confirmar"
          class="delete-facility"
          showCancel={true}
          cancelText="Cancelar"
          cancelButtonType="secondary"
          closeAction={() => {
            if (this.props.isModalOpen) this.props.isModalOpen(false);
            this.setState({ showUnreadNotificationModal: false })}
          }
          acceptAction={this.markUnreadNotification}
        />
        <ConfirmDialog
          open={this.state.showDeleteNotificationModal}
          title="Eliminar alerta"
          message={"¿Estás seguro que deseas eliminar esta notificación?"}
          acceptText="Confirmar"
          class="delete-facility"
          showCancel={true}
          cancelText="Cancelar"
          cancelButtonType="secondary"
          closeAction={() => {
            if (this.props.isModalOpen) this.props.isModalOpen(false);
            this.setState({ showDeleteNotificationModal: false })}
          }
          acceptAction={this.deleteNotification}
        />
        <ConfirmDialog
          open={this.state.showReadAlertModal}
          title="Marcar como leída"
          message={"¿Estás seguro que deseas marcar esta alerta como leída?"}
          // contentObject={<UserChipContainer/> }
          acceptText="Confirmar"
          class="delete-facility"
          showCancel={true}
          cancelText="Cancelar"
          cancelButtonType="secondary"
          closeAction={() => {
            if (this.props.isModalOpen) this.props.isModalOpen(false);
            this.setState({ showReadAlertModal: false })}
          }
          acceptAction={this.markRead}
        />
        <ConfirmDialog
          open={this.state.showUnreadAlertModal}
          title="Marcar como no leída"
          message={"¿Estás seguro que deseas marcar esta alerta como no leída?"}
          // contentObject={<UserChipContainer/> }
          acceptText="Confirmar"
          class="delete-facility"
          showCancel={true}
          cancelText="Cancelar"
          cancelButtonType="secondary"
          closeAction={() => {
            if (this.props.isModalOpen) this.props.isModalOpen(false);
            this.setState({ showUnreadAlertModal: false })}
          }
          acceptAction={this.markUnread}
        />
        <ConfirmDialog
          open={this.state.showDeleteAlertModal}
          title="Eliminar alerta"
          message={"¿Estás seguro que deseas eliminar esta alerta?"}
          // contentObject={<UserChipContainer/> }
          acceptText="Confirmar"
          class="delete-facility"
          showCancel={true}
          cancelText="Cancelar"
          cancelButtonType="secondary"
          closeAction={() => {
            if (this.props.isModalOpen) this.props.isModalOpen(false);
            this.setState({ showDeleteAlertModal: false })}
          }
          acceptAction={this.deleteAlert}
        />
        <ConfirmDialog
          open={this.state.showUnsubscribeAlertModal}
          title="De-suscribirse de la alerta"
          message={"¿Estás seguro que deseas de-suscribirte de esta alerta?"}
          // contentObject={<UserChipContainer/> }
          acceptText="Confirmar"
          class="delete-facility"
          showCancel={true}
          cancelText="Cancelar"
          cancelButtonType="secondary"
          closeAction={() => {
            if (this.props.isModalOpen) this.props.isModalOpen(false);
            this.setState({ showUnsubscribeAlertModal: false })}
          }
          acceptAction={this.unsubscribeAlert}
        />
        <ConfirmDialog
          open={this.state.showCouponNotificationModal}
          title="Redimir cupón"
          message={"¿Estás seguro que deseas redimir el cupón?"}
          acceptText="Confirmar"
          class="delete-facility"
          showCancel={true}
          cancelText="Cancelar"
          cancelButtonType="secondary"
          closeAction={() => {
            if (this.props.isModalOpen) this.props.isModalOpen(false);
            this.setState({ showCouponNotificationModal: false })}
          }
          acceptAction={this.redeemCoupon}
        />
      </React.Fragment>
      // <></>
    );
  }
}


const mapStateToProps = (state) => {
  const companyId: number = state.globalCatalog.session.company.id;

  let alert_details = getEntityItems(state, "COMPANIES.CATALOGS.ALERTCOUNTER.DETAIL");
  let notifications = (getEntityItems(state, "COMPANIES.NOTIFICATIONS.CONTROL.MESSAGES").notifications || []).map( item => {
    item.is_notification = true;
    return item
  });

  alert_details = alert_details.concat(notifications)

  return {
    companyId,
    userData: state.globalCatalog.session.company?.companyuser?.id || 0,
    userId: state.globalCatalog.session.user.id,
    alert_details
  };
};

const mapDispatchToProps = (dispatch) => {
  dispatch(loadSession());
  return {
    loadAlertDetail: (company_id,user_company,action) =>{
      dispatch(
        readEntities("COMPANIES.CATALOGS.ALERTCOUNTER.DETAIL", { company_id, user_company,action })
      );
    },
    loadNotifications: (params, opt) => dispatch(readEntities("COMPANIES.NOTIFICATIONS.CONTROL.MESSAGES", params, opt)),
    attendAlert: (params, opt) =>{
      dispatch(
        readEntity("COMPANIES.ALERTS.NOTIFICATIONCENTER.ATTENDED", params, opt)
      );
    },
    deleteAlert: (params, opt) =>{
      dispatch(
        readEntity("COMPANIES.ALERTS.NOTIFICATIONCENTER.DELETED", params, opt)
      );
    },
    unsubscribeAlert: (params, opt) =>{
      dispatch(
        readEntity("COMPANIES.ALERTS.NOTIFICATIONCENTER.UNSUBSCRIBED", params, opt)
      );
    },
    setOrderDetail: (payload) => dispatch(setOrderSeleted(payload)),
    setOrderCommentsSeleted: () => dispatch(setOrderCommentsSeleted()),
    editNotification: (params, opt) => dispatch(partialEntity("COMPANIES.NOTIFICATIONS.CONTROL.MESSAGES.ACTIONS", params, opt)),
    deleteNotification: (params, opt) => dispatch(deleteEntity("COMPANIES.NOTIFICATIONS.CONTROL.MESSAGES.ACTIONS", params, opt)),
    redeemCoupon: (params, opt) => dispatch(createEntity("COMPANIES.COUPON.REDEEM", params, opt)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuAlertsDetails);