import React from "react";
import { ActionsTricolon } from "../../shared/controls/actions-tricolon";
import AccountIcon from "../../shared/icons/alerts/account";
import CallIcon from "../../shared/icons/alerts/call";
import ChatIcon from "../../shared/icons/alerts/chat";
import FillIcon from "../../shared/icons/alerts/fill";
import OrderIcon from "../../shared/icons/alerts/order";
import ReportIcon from "../../shared/icons/alerts/report";
import TravelIcon from "../../shared/icons/alerts/travel";
import ZoneIcon from "../../shared/icons/alerts/zone";
import {
  DetailsCards,
  DetailsCardsHeader,
  DetailsCardsContent,
} from "./styled";
import { connect } from "react-redux";
import { readEntity } from "../../redux/api";
import moment from "moment";
import styled from "styled-components";
import { validateObjPerms } from "../../redux/reducers/global-catalog.reducer";

class MenuAlertsDetailsCards extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  getIcon = (type, level) => {
    switch (type) {
      case 1:
        switch(level){
          case 1:
          case 2:
            return <FillIcon width="20px" height="20px" viewBox="10 0 48 45" fill="#FFE358"/>;
          case 3:
          case 4:
            return <FillIcon width="20px" height="20px" viewBox="10 0 48 45" fill="#FF9145"/>;
          case 5:
            return <FillIcon width="20px" height="20px" viewBox="10 0 48 45" />;
          default: 
            return <FillIcon width="20px" height="20px" viewBox="10 0 48 45" />;
        }
      case 2:
        return <ChatIcon width="20px" height="20px" viewBox="0 0 40 30" />;
      case 3:
        return <CallIcon width="20px" height="20px" viewBox="0 0 30 40" />;
      case 4:
        return <OrderIcon width="20px" height="20px" viewBox="15 5 40 40" />;
      case 5:
        return <AccountIcon width="20px" height="20px" viewBox="0 0 34 30" />;
      case 6:
        return <ReportIcon width="20px" height="20px" viewBox="0 0 35 30" />;
      case 7:
        return <TravelIcon width="20px" height="20px" viewBox="0 0 35 25" />;

      default:
        return <ZoneIcon width="20px" height="20px" viewBox="0 0 20 30" />;
    }
  };

  getTime = (time) => {
    let date = time.split(":");
    let hours = date[0];
    let minutes = date[1];
    let ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  };

  getUrl = (object,facility,alert,notification,alert_date,notification_data) => {
    let url = null

    if (notification_data) {
      return `/company/controls/shipment/orderdetail/${notification_data.trip_id}`;
    }

    if (alert && alert===6 && facility && object.search){

      let date = new Date(object.date)
      // Alerta de retraso a cita (carga o descarga) 
      url = `/company/controls/shipment/detail/${facility}/?search=${object.search}&date=${
        ''.concat(date.getFullYear(), '-',("0" + (date.getMonth() + 1)).slice(-2),'-', ("0" + date.getDate()).slice(-2))}`

    } else if (facility && object.search){
      url = `/company/controls/shipment/detail/${facility}/?search=${object.search}`
    } else {
      const { type = '', search = '' } = object;
      if (type === 'assembly') {
        url = `/company/controls/gps/?search=${search}`
      }
    }

    return url
  }

  openUrl = (url, notification_data) => {
    const { markReadNotification } = this.props.card;

    if (markReadNotification) {
      markReadNotification();
    }

    this.props.history.push(url);

    if (notification_data && this.props.setOrderDetail) {
      this.props.setOrderDetail(notification_data.code || '');
    } else {
      document.location.reload(true);
    }

  }

  isRedeemded = (data) => {
    return data.type_notification === 47 && data.extra_data && data.extra_data.status.id === 2
  }

  userHasPerms = () => {
    // Se valida si el usuario tiene permisos para editar perfil de la compañia
    let response = this.props.validateObjPerms(
      [
        {
          id: "10",
          perm: "u"
        }
      ], 
      {
        display: true,
      }
    );
    
    return Object.keys(response).length;
  }

  buildNotificationMessageWithTitle = (description, notification_data) => {

    if ('html' in notification_data) {
      return(
        <div>
          { <div dangerouslySetInnerHTML={{__html:notification_data['html']}} /> }
        </div>
      );
    }

    if ('title' in notification_data && 'body' in notification_data) {
      return(
        <NotificationMessage>
          {notification_data['title']}
          <br/>
          <br/>
          {notification_data['body']}

          {
            this.userHasPerms() &&
            <>
              <br/>
              <br/>
              <span class={'link'}>{'Verifica tu empresa aquí'}</span>
              <span>{' para que se les generen cupones a ellos por haberte invitado a Orbinetwork.'}</span> 
            </>
          }
        </NotificationMessage>
      );
    }

    return description;
  }

  render() {

    const { type, title, time, description, level, object, facility, alert, notification, alert_date, attended, actions, notification_data, deleteNotification, type_notification, is_notification, markReadNotification } = this.props.card;
    const url = this.getUrl(object,facility,alert,notification,alert_date, notification_data)

    let is_coupon = is_notification && type_notification === 47;

    return (
      <DetailsCards onClick={() => {

        if ('url' in notification_data) {
          if (markReadNotification) {
            markReadNotification();
          }
          this.props.history.push(notification_data['url']);
          return;
        }

        if ((description || '').includes('verificada correctamente')) {
          return;
        }

        if ('html' in notification_data) {
          return;
        }

        if (is_coupon) {
          if ('title' in notification_data && 'body' in notification_data && this.userHasPerms()) {
            this.props.history.push(`/company/data/${this.props.orbi_id}`);
            return;
          }

          let action = actions.filter(item => item.description === 'Redimir');

          if (action.length > 0) { action[0].action(); }
          return;
        }

        if (url === null || this.props.isLoadingValidationTrip) {
          return;
        }
        
        this.props.validateTrip(this.props.companyId, notification_data.trip_id, {
          onSuccess: (t) => {
            if ('belonging' in t && t.belonging === true) {
              this.openUrl(url, notification_data);
            } else {
              if (this.props.errorOpenTrip && deleteNotification) {
                deleteNotification();
                this.props.errorOpenTrip();
              }
            }
          },
          onError: () => this.openUrl(url, notification_data)
        })
       
      }}>
        <div style={{
          position: "absolute",
          top: "0px",
          left: "0px",
          width: "5px",
          height: "100%",
          backgroundColor: attended ? "#298dec00" : "#298dec" ,
          borderRadius: "4px 0 0 4px"
        }}/>
        <DetailsCardsHeader>
          <div className="icon">{this.getIcon(type, level)}</div>
          <div className={"title".concat(attended ? " read" : "")}>{title}</div>
          <div className="time">{this.getTime(time)}</div>
        </DetailsCardsHeader>
        <DetailsCardsContent>
          <div className="description">
            {this.buildNotificationMessageWithTitle(description, notification_data)}
            {/* {description} */}
            {(is_coupon && this.isRedeemded(this.props.card)) && <div className="footer">{`Redimido el ${moment(attended).format('DD MMM YYYY')}`}</div>}
          </div>
          <div className="dot" /* onClick={(e) => {e.stopPropagation(); console.log("clicking")}} */>
            <ActionsTricolon
              tooltipPlacement={"left"}
              actions={actions}
            />
          </div>
          
        </DetailsCardsContent>
      </DetailsCards>
    );
  }
}

const mapStateToProps = (state) => {
  const companyId = state.globalCatalog.session.company.id;
  const orbi_id = state.globalCatalog.session.company.orbi_id;
  const isLoadingValidationTrip = state?.api['COMPANIES.TRIPS.VALIDATIONS']?.status?.isFetching || false;

  return {
    orbi_id,
    companyId,
    isLoadingValidationTrip
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    validateTrip: (company_id, trip_id, opt) =>
      dispatch(readEntity("COMPANIES.TRIPS.VALIDATIONS", { company_id, trip_id, action: "belonging" }, opt)),
      validateObjPerms: (perms, obj, evalPerm) => validateObjPerms(perms, obj, evalPerm)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MenuAlertsDetailsCards);

export const NotificationMessage = styled.div`
  .link {
    text-decoration: underline;
  }
`;