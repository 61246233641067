import React from "react";
import { withRouter } from "react-router-dom";
import { View, ViewTitle } from '../../../shared/styled/view';
import { connect } from "react-redux";
import { getEntityItems, partialEntity, readEntities, readEntity } from "../../../redux/api";
import GenericDialog from "../../../shared/dialog/generic-modal";
import styled from "styled-components";
import BillingDataView from "./billing-data-view";
import SubscriptionShoppingCarView from "../subscription/subscription-shopping-car-view";
import { validateEmails, validateJson } from "../../../shared/utils";
import { isArray } from 'lodash';
import ConfirmDialog from "../../../shared/dialog/confirm-dialog";
import SelectInput from "../../../shared/controls/select-input";
import Checkbox from "../../../shared/controls/checkbox";
import moment from 'moment';
import Button from "../../../shared/controls/button";
import PDFIcon from '../../../shared/icons/pdf-icon';
import ReactTooltip from 'react-tooltip';
import { TooltipContainer } from "./../dashboard/style";
import { openSecureDoc, getUrlsEnv } from '../../../shared/backend-api';
import XMLIcon from '../../../shared/icons/xml-icon';

class WalletOrderView extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      orderId: this.props.match?.params?.orderId || 0,
      showEditModal: false,
      updateOrderData: true,
      billOrderModal: false,

      billingActivated: '2',
      billUse: 0,
      paymentMethod: '15',
      info: {
        rfc: '',
        razon_social: '',
        postal_code: '',
        regimen: 0,
        email_billing: ''
      },
      rfc: '',
      razon_social: '',
      postal_code: '',
      regimen: 0,
      email_billing: '',

      errorMsg: ''
    }
  }

  componentDidMount() {
    this.props.loadFormaDePago();
    this.props.loadUsoCfdi();
    this.props.loadTaxRegime();

    this.props.getOrder({
      company_id: this.props.companyId,
      order_id: this.state.orderId
    }, {
      onSuccess: (response) => {
        this.setInvoiceData(response.data || {});
      },
      onError: (e) => {
        this.setState({
          errorMsg: e.response?.data?.error || "Ocurrió un error, intente de nuevo"
        });
      }
    });

    document.addEventListener('page-content-scroll', this.handlePageContentScroll);
    document.addEventListener('mousedown', this.handlePageContentScroll, false);
  }

  componentWillUnmount() {
    document.removeEventListener('page-content-scroll', this.handlePageContentScroll);
    document.removeEventListener('mousedown', this.handlePageContentScroll, false);
  }

  handlePageContentScroll = (e) => {
    if (e && e.target && e.target.id && (e.target.id.includes('download-document') || e.target.id.includes('see-document'))) {
      return;
    }
    ReactTooltip.hide();
  }

  setInvoiceData = (response) => {
    try { 
      const data = response.billing_data || response.data || {};

      this.state.billUse = data.bill_use;

      this.state.rfc = data.rfc;
      this.state.razon_social = data.razon_social;
      this.state.email_billing = data.mail || data.email;
      this.state.postal_code = data.postal_code;
      this.state.regimen = data.regimen_fiscal;

      this.state.info.rfc = data.rfc;
      this.state.info.razon_social = data.razon_social;
      this.state.info.email_billing = data.mail || data.email;
      this.state.info.postal_code = data.postal_code;
      this.state.info.regimen = data.regimen_fiscal;

      this.setState({});
    } catch(error) {
      console.log('Error en acceder a la info de invoice_data', error);
    }
  }

  getInfoToShow = () => {
    let info = this.state.info;

    let tax_regime = this.props.taxRegimes.filter( item => `${item.id}` === `${info.regimen}` )

    if (tax_regime && tax_regime.length > 0) {
      info.regimen_fiscal = tax_regime[0].description;
    } else {
      info.regimen_fiscal = '';
    }

    return info;
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    if (value !== null && value !== undefined && !isNaN(value)) {
      this.setState({ [name]: value});
    }
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  buildJsonToSend = () => {
    return({
      company_id: this.props.companyId,
      rfc: this.state.rfc,
      razon_social: this.state.razon_social,
      postal_code: this.state.postal_code,
      email: validateEmails(this.state.email_billing),
      regimen_fiscal: this.state.regimen,
      bill_use: this.state.billUse,
      payment_method: this.state.paymentMethod
    });
  }

  updateInvoiceData = () => {
    this.props.updateOrder({
      action: 'update_data',
      company_id: this.props.companyId,
      order_id: this.state.orderId,
      data: this.buildData()
    }, {
      onSuccess: (response) => {
        if (this.state.updateOrderData) {
          this.props.updateInvoiceData(
            this.buildJsonToSend(), 
            {
              onSuccess: (response) => {
                this.state.showEditModal = false;
                this.state.updateOrderData = true;
                this.setInvoiceData(response);
              }
            }
          );
        } else {
          this.state.showEditModal = false;
          this.state.updateOrderData = true;
          this.setInvoiceData(response.data || {});
        }
      },
      onError: (e) => {
        this.setState({
          errorMsg: e.response?.data?.error || "Ocurrió un error, intente de nuevo"
        });
      }
    }) 
  }

  buildData = () => {

    let regimen_fiscal_description = '';
    let bill_use_description = '';
    let payment_method_description = '';

    let tax_regime = this.props.taxRegimes.filter( item => `${item.id}` === `${this.state.regimen}`);
    if (tax_regime && tax_regime.length > 0) {
      regimen_fiscal_description = tax_regime[0].description;
    }

    let bill_use = this.props.usoCfdi.filter( item => `${item.id}` === `${this.state.billUse}`);
    if (bill_use && bill_use.length > 0) {
      bill_use_description = bill_use[0].description;
    }

    let payment_method = this.props.formaDePago.filter( item => `${item.id}` === `${this.state.paymentMethod}`);
    if (payment_method && payment_method.length > 0) {
      payment_method_description = payment_method[0].description;
    }

    return {
      general_data: this.props.wallet_order?.data?.general_data || {},
      billing_data: {
        rfc: this.state.rfc,
        razon_social: this.state.razon_social,
        postal_code: this.state.postal_code,
        regimen_fiscal: this.state.regimen,
        regimen_fiscal_description,
        email: validateEmails(this.state.email_billing),

        clabe: this.props.walletInfo?.clabe || '',
        billingActivated: this.state.billingActivated === '1',
        bill_use: this.state.billUse,
        bill_use_description,
        payment_method: this.state.paymentMethod,
        payment_method_description
      }
    };
  }

  userCanEditBillingData = () => {
    if (this.props.wallet_order) {
      if (this.props.wallet_order.status === 1) {
        return true
      }

      if (this.props.wallet_order.status === 2) {
        return moment().month() === moment(this.props.wallet_order.movement_date).month();
      }
    }

    return false
  }

  userCanBillOrder = () => {
    if (this.props.wallet_order) {
      if (this.props.wallet_order.status === 2) {
        return moment().month() === moment(this.props.wallet_order.movement_date).month();
      }
    }

    return false
  }

  downloadDocument = (url) => {
    openSecureDoc(getUrlsEnv().files.concat(url)).then((response) => {
      var array = url.split("/");
      var lastsegment = array.length > 0 ? array[array.length - 1] : ".";

      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);

        let a = document.createElement("a");
        a.href = url;
        a.download = lastsegment;
        a.click();
      });
    });
  };

  showDocument = (url) => {
    window.open(getUrlsEnv().files.concat(url), "_blank");
  };

  buildFilesComponent = () => {
    let pdf_order = this.props.wallet_order?.pdf_order || '';
    let pdf_invoice = this.props.wallet_order?.pdf_invoice || '';
    let pdf_receipt = this.props.wallet_order?.pdf_receipt || '';
    let xml_invoice = this.props.wallet_order?.xml_invoice || '';

    if (pdf_order === '' && pdf_invoice === '' && pdf_receipt === '' && xml_invoice === '') {
      return '';
    }

    return(
      <div
        style={{
          display: 'flex',
          fontSize: 17,
          color: 'white',
          marginTop: 20,
          marginBottom: 30
        }}
      >
        {
          (pdf_order !== '') &&
          <>
            <div 
              className="icon"
              data-tip
              data-for={`order-icon`}
              onMouseEnter={(e) => {
                ReactTooltip.hide();
                ReactTooltip.show(e.currentTarget);
              }}
              style={{display: 'flex', marginRight: 20, alignItems: 'center'}}
            >
              <PDFIcon
                height="15" 
                fill={'white'}
              />
              <div>Orden</div>
            </div>
            <ReactTooltip
              id={`order-icon`}
              delayHide={20}
              delayShow={20}
              delayUpdate={200}
              place={"bottom"}
              border={true}
              type={"light"}
              effect="solid"
              clickable={true}
              event={'click'}
              eventOff={'click'}
            >
              <TooltipContainer>
                <div 
                  className="option"
                  id={"see-document"}
                  onClick={(e) => {
                    ReactTooltip.hide();
                    e.stopPropagation(); 

                    this.showDocument(pdf_order.substring(1));
                  }}
                >
                  {`Ver archivo`}
                </div>
                <div
                  className="option"
                  id={"download-document"}
                  onClick={(e) => {
                    ReactTooltip.hide();
                    e.stopPropagation(); 

                    this.downloadDocument(pdf_order.substring(1));
                  }}
                >
                  {`Descargar archivo`}
                </div>
              </TooltipContainer>
            </ReactTooltip>
          </>
        }

        {
          (pdf_receipt !== '') &&
          <>
            <div 
              className="icon"
              data-tip
              data-for={`receipt-icon`}
              onMouseEnter={(e) => {
                ReactTooltip.hide();
                ReactTooltip.show(e.currentTarget);
              }}
              style={{display: 'flex', marginRight: 20, alignItems: 'center'}}
            >
              <PDFIcon
                height="15" 
                fill={'white'}
              />
              <div>Recibo</div>
            </div>

            <ReactTooltip
              id={`receipt-icon`}
              delayHide={20}
              delayShow={20}
              delayUpdate={200}
              place={"bottom"}
              border={true}
              type={"light"}
              effect="solid"
              clickable={true}
              event={'click'}
              eventOff={'click'}
            >
              <TooltipContainer>
                <div 
                  className="option"
                  id={"see-document"}
                  onClick={(e) => {
                    ReactTooltip.hide();
                    e.stopPropagation(); 

                    this.showDocument(pdf_receipt.substring(1));
                  }}
                >
                  {`Ver archivo`}
                </div>
                <div
                  className="option"
                  id={"download-document"}
                  onClick={(e) => {
                    ReactTooltip.hide();
                    e.stopPropagation(); 

                    this.downloadDocument(pdf_receipt.substring(1));
                  }}
                >
                  {`Descargar archivo`}
                </div>
              </TooltipContainer>
            </ReactTooltip>
          </>
        }

        {
          (pdf_invoice !== '') &&
          <>
            <div 
              className="icon"
              data-tip
              data-for={`invoice-icon`}
              onMouseEnter={(e) => {
                ReactTooltip.hide();
                ReactTooltip.show(e.currentTarget);
              }}
              style={{display: 'flex', marginRight: 20, alignItems: 'center'}}
            >
              <PDFIcon
                height="15" 
                fill={'#3d77f7'}
              />
              <div>Factura pdf</div>
            </div>

            <ReactTooltip
              id={`invoice-icon`}
              delayHide={20}
              delayShow={20}
              delayUpdate={200}
              place={"bottom"}
              border={true}
              type={"light"}
              effect="solid"
              clickable={true}
              event={'click'}
              eventOff={'click'}
            >
              <TooltipContainer>
                <div 
                  className="option"
                  id={"see-document"}
                  onClick={(e) => {
                    ReactTooltip.hide();
                    e.stopPropagation(); 

                    this.showDocument(pdf_invoice.substring(1));
                  }}
                >
                  {`Ver archivo`}
                </div>
                <div
                  className="option"
                  id={"download-document"}
                  onClick={(e) => {
                    ReactTooltip.hide();
                    e.stopPropagation(); 

                    this.downloadDocument(pdf_invoice.substring(1));
                  }}
                >
                  {`Descargar archivo`}
                </div>
              </TooltipContainer>
            </ReactTooltip>
          </>
        }

        {
          (xml_invoice || '') !== '' &&
          <>
            <div 
              className="icon"
              data-tip
              data-for={`xml_invoice-icon`}
              onMouseEnter={(e) => {
                ReactTooltip.hide();
                ReactTooltip.show(e.currentTarget);
              }}
              style={{display: 'flex', marginRight: 20, alignItems: 'center'}}
            >
              <XMLIcon
                height="15" 
                fill={'#3d77f7'}
              />
              <div>Factura xml</div>
            </div>

            <ReactTooltip
              id={`xml_invoice-icon`}
              delayHide={20}
              delayShow={20}
              delayUpdate={200}
              place={"bottom"}
              border={true}
              type={"light"}
              effect="solid"
              clickable={true}
              event={'click'}
              eventOff={'click'}
            >
              <TooltipContainer>
                <div 
                  className="option"
                  id={"see-document"}
                  onClick={(e) => {
                    ReactTooltip.hide();
                    e.stopPropagation(); 

                    this.showDocument(xml_invoice.substring(1));
                  }}
                >
                  {`Ver archivo`}
                </div>
                <div
                  className="option"
                  id={"download-document"}
                  onClick={(e) => {
                    ReactTooltip.hide();
                    e.stopPropagation(); 

                    this.downloadDocument(xml_invoice.substring(1));
                  }}
                >
                  {`Descargar archivo`}
                </div>
              </TooltipContainer>
            </ReactTooltip>
          </>
        }
      </div>
    );

  }

  render() {

    console.log(this.props.wallet_order)

    return(
      <View>
        <ViewTitle>
          <div style={{flex: "1"}}>
            {`Pedido #${this.state.orderId}`}
          </div>
        </ViewTitle>
        <ViewTitle>
          <div style={{flex: "1"}}>
            {`Estatus: ${this.props.wallet_order?.order_status_description || ''}`}
          </div>
        </ViewTitle>

        { this.buildFilesComponent() }

        <ContainerView>

          {
            this.userCanEditBillingData() ? 
            <BillingDataView
              company={this.props.company}
              info={this.getInfoToShow()}
              editAction={() => { this.setState({showEditModal: true}) }}
              billingActivated={this.state.billingActivated}
              onChange={this.handleChange}
              formaDePago={this.props.formaDePago}
              usoCfdi={this.props.usoCfdi}
              billUse={this.state.billUse}
              paymentMethod={this.state.paymentMethod}
              clabe={this.props.walletInfo?.clabe || ''}
            /> :
            <BillingDataView
              data={this.props.wallet_order?.data?.billing_data || {}}
            />
          }

          <SubscriptionShoppingCarView
            data={this.props.wallet_order?.data || {}}
            editable={true}
            walletOrder={this.props.wallet_order}
            couponSelected={this.props.wallet_order?.coupon || null}
          />

        </ContainerView>
        
        {
          this.userCanBillOrder() &&
          <div
            style={{
              display: 'flex',
              marginTop: 20,
              justifyContent: 'end',
              paddingRight: 20
            }}
          >
            <Button
              text={"Facturar"}
              type="primary"
              disabled={this.state.billingActivated !== '1'}
              onClick={() => this.setState({billOrderModal: true})}
            />
          </div>
        }

        <GenericDialog
          open={this.state.errorMsg !== ''}
          title={'Error'}
          message={this.state.errorMsg}
          acceptAction={() => { this.setState({errorMsg: ''}) }}
          closeAction={() => { this.setState({errorMsg: ''}) }}
        />

        <GenericDialog
          open={this.state.billOrderModal}
          title={'Facturar pedido'}
          message={"Mensaje de prueba"}
          acceptAction={() => { this.setState({billOrderModal: false}) }}
          closeAction={() => { this.setState({billOrderModal: false}) }}
        />

        <ConfirmDialog
          open={this.state.showEditModal}
          isAcceptLoading={this.props.isLoadingInvoiceData}
          title="Datos de facturación"
          class='no-bottom-margin-2'
          contentObject={
            <div>
              <ModalContainerView>
                <ModalNameFieldView>
                  <ModalFieldView>{'RFC:'}</ModalFieldView>
                  <ModalFieldView>{'Razón social:'}</ModalFieldView>
                  <ModalFieldView>{'Código Postal:'}</ModalFieldView>
                  <ModalFieldView>{'Regimen fiscal:'}</ModalFieldView>
                  <ModalFieldView>{'Correos electronicos:'}</ModalFieldView>
                </ModalNameFieldView>

                <ModalInputFieldView>

                  <ModalFieldView>
                    <TextInput
                      name="rfc"
                      type="text"
                      value={this.state.rfc}
                      onChange={this.handleInputChange}
                    />
                  </ModalFieldView>

                  <ModalFieldView>
                    <TextInput
                      name="razon_social"
                      type="text"
                      value={this.state.razon_social}
                      onChange={this.handleInputChange}
                    />
                  </ModalFieldView>

                  <ModalFieldView>
                    <TextInput
                      name="postal_code"
                      type="text"
                      value={this.state.postal_code}
                      onChange={this.handleInputChange}
                    />
                  </ModalFieldView>

                  <ModalFieldView>
                  <SelectInput
                      label='Régimen Fiscal:'
                      value={parseInt(this.state.regimen)}
                      name='regimen'
                      items={this.props.taxRegimes}
                      onChange={this.handleInputChange}
                    />
                  </ModalFieldView>

                  <ModalFieldView>
                    <TextInput
                      name="email_billing"
                      type="text"
                      value={this.state.email_billing}
                      onChange={this.handleInputChange}
                    />
                  </ModalFieldView>

                </ModalInputFieldView>
              </ModalContainerView>

              <div style={{marginTop: 20}}/>

              <Checkbox
                onChange={() => this.setState({updateOrderData: !this.state.updateOrderData})}
                item={{
                  description: "Actualizar las siguientes facturas",
                  id: 1,
                }}
                name="updateOrderData"
                active={this.state.updateOrderData}
              />
            </div>
          }
          acceptText="Guardar"
          showCancel={true}
          cancelText="Cerrar"
          cancelButtonType="secondary"
          closeAction={() => {
            this.state.rfc = this.state.info.rfc;
            this.state.razon_social = this.state.info.razon_social;
            this.state.email_billing = this.state.info.email_billing;
            this.state.postal_code = this.state.info.postal_code;
            this.state.regimen = this.state.info.regimen;
            this.state.updateOrderData = true;

            this.setState({showEditModal: false })
          }}
          acceptAction={() => {
            this.updateInvoiceData();
          }}
        />
      </View>
    );
  }

}

const mapStateToProps = (state) => {
  const wallet_order = getEntityItems(state, "COMPANIES.WALLETMOVEMENTS.ORDERDETAIL");
  const taxRegimes = getEntityItems(state, "COMPANIES.SAT.CATALOGS.REGIMENFISCAL").map(p => ({id: p.id, description: p.clave.concat('-', p.descripcion)}));
  const usoCfdi = getEntityItems(state, "COMPANIES.SAT.CFDI.USOCFDI");
  const formaDePago = getEntityItems(state, "COMPANIES.SAT.CFDI.FORMADEPAGO");
  const isLoadingInvoiceData = state?.api['COMPANIES.INVOICEDATA']?.status?.isFetching || false;
  const isLoadingData = state?.api['COMPANIES.WALLETMOVEMENTS.CANCEL']?.status?.isFetching || false;

  return {
    formaDePago: (isArray(formaDePago) ? formaDePago : []).map(item => ({id: item.id, description: `${item.clave} ${item.descripcion}`})),
    usoCfdi: (isArray(usoCfdi) ? usoCfdi : []).map(item => ({id: item.id, description: `${item.clave} ${item.descripcion}`})),
    taxRegimes,
    company: state.globalCatalog.session.company,
    companyId: state.globalCatalog.session.company.id,
    wallet_order : validateJson(wallet_order) ? wallet_order : null,
    isLoadingInvoiceData: isLoadingData || isLoadingInvoiceData
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getOrder: (params, opt) => dispatch(readEntity("COMPANIES.WALLETMOVEMENTS.ORDERDETAIL", params, opt)),
    loadUsoCfdi: () => dispatch(readEntities("COMPANIES.SAT.CFDI.USOCFDI", {}, {prefix: 'orbisat.'})),
    loadTaxRegime:  () => dispatch(readEntities("COMPANIES.SAT.CATALOGS.REGIMENFISCAL", {}, {prefix: 'orbisat.'})),
    loadFormaDePago: () => dispatch(readEntities("COMPANIES.SAT.CFDI.FORMADEPAGO", {}, {prefix: 'orbisat.'})),
    updateInvoiceData: (params, opt) => dispatch(partialEntity("COMPANIES.INVOICEDATA", params, opt)),
    updateOrder: (params, opt) => dispatch(partialEntity("COMPANIES.WALLETMOVEMENTS.CANCEL", params, opt))
  };
}

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(WalletOrderView));

const ContainerView = styled.div`
  display: flex; 
  align-items: center;
  flex-direction: column;
  padding-right: 20px;
  padding-left: 20px;
  margin-top: 20px;
`;

const ModalContainerView = styled.div`
  display: flex;
`;

const ModalNameFieldView = styled.div`
  width: 30%;
`;

const ModalFieldView = styled.div`
  height: 60px;
  width: auto;
  margin-top: 1px;
  color: #252E47;
  font-size: 14px;
  display: flex;
  align-items: center;
`;

const ModalInputFieldView = styled.div`
  width: 70%;
`;

const TextInput = styled.input`
  background: white;
  border: none;
  width: 100%;
  height: 30px;
  color: black;
  font-size: 14px;
  border: 2px solid #AFAFB0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;