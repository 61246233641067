import React from "react";
import styled from "styled-components";
import RadioInput from "../../../shared/controls/radio-input";
import SelectInput from '../../../shared/controls/select-input';

class BillingDataView extends React.Component {

  handleChange = (e) => {
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  }

  buildBillingInfo = () => {
    return(
      <div style={{
        display: 'flex',
      }}>

        <div style={{
          width: 'calc(50% - 20px)'
        }}>
          <PaymentTitle>{'Forma de pago: Transferencia interbancaria'}</PaymentTitle>

          <InfoTitle>{'Banco: Sistema de Pagos STP SA de CV'}</InfoTitle>
          <InfoTitle>{`Beneficiario: On Tech Development S de RL de CV`}</InfoTitle>
          <InfoTitle>{`Clabe Interbancaria: ${this.props.data ? (this.props.data?.clabe || '') : this.props.clabe}`}</InfoTitle>

          <BillingContainer>

            <InfoTitle>{'Deseas Facturar:'}</InfoTitle>

            {
              this.props.data ?
              <div style={{color: 'white', marginLeft: 10}}>
                {('billingActivated' in this.props.data ? this.props.data.billingActivated : false) ? 'Sí' : 'No'}
              </div>
              :
              <>
                <RadioInputStyled>
                  <RadioInput
                    className="radio-input"
                    name="billingActivated"
                    title="Sí"
                    value="1"
                    selected={this.props.billingActivated}
                    onClick={this.handleChange}
                  />
                </RadioInputStyled>

                <RadioInputStyled>
                  <RadioInput
                    className="radio-input"
                    name="billingActivated"
                    title="No"
                    value="2"
                    selected={this.props.billingActivated}
                    onClick={this.handleChange}
                  />
                </RadioInputStyled> 
              </>
            }

          </BillingContainer>

          {
            (this.props.data ? this.props.data.billingActivated ? '1' : '2' : this.props.billingActivated) === '1' &&
            <BillingFormContainer>
              
              {
                this.props.data ? 
                <>
                  <div style={{color: 'white', fontSize: 12, opacity: 0.4, marginBottom: 5}}>{'Uso de la factura:'}</div>
                  <div style={{color: 'white', fontSize: 16}}>{`${this.props.data.bill_use_description}`}</div>

                  <div style={{color: 'white', fontSize: 12, marginTop: 10, opacity: 0.4, marginBottom: 5}}>{'Forma de pago:'}</div>
                  <div style={{color: 'white', fontSize: 16}}>{`${this.props.data.payment_method_description}`}</div>
                </> :
                <>
                  <SelectInput
                    label='Uso de la factura:'
                    name='billUse'
                    items={this.props.usoCfdi}
                    value={parseInt(this.props.billUse)}
                    onChange={this.handleChange}
                  />

                  <SelectInput
                    label='Forma de pago:'
                    name='paymentMethod'
                    items={this.props.formaDePago}
                    value={parseInt(this.props.paymentMethod)}
                    onChange={this.handleChange}
                    disabled
                  /> 
                </>
              }
            </BillingFormContainer>
          }

        </div>

        <BillingContainerView>
          <BillingTitleContainerView>
            <BillingTitleView>{'Datos de facturación'}</BillingTitleView>

            {
              this.props.data ? '' :
              <EditView 
                alt="" 
                className="logo" 
                src="/images/edit.svg" 
                onClick={() => {
                  if (this.props.editAction) {
                    this.props.editAction()
                  }
                }}/>
            }

          </BillingTitleContainerView>

          {
            (this.props.info || this.props.data) &&
            <>
              <BillingInfoView>{this.props.data ? this.props.data.rfc : this.props.info.rfc}</BillingInfoView>
              <BillingInfoView>{`Razon social: ${this.props.data ? this.props.data.razon_social : this.props.info.razon_social}`}</BillingInfoView>
              <BillingInfoView>{`C.P.: ${this.props.data ? this.props.data.postal_code : this.props.info.postal_code}`}</BillingInfoView>
              <BillingInfoView>{`Regimen fiscal: ${this.props.data ? this.props.data.regimen_fiscal_description : this.props.info.regimen_fiscal}`}</BillingInfoView>
              <BillingInfoView>{`Correos: ${this.props.data ? this.props.data.mail : this.props.info.email_billing}`}</BillingInfoView>
            </>
          }
          
        </BillingContainerView>
      </div>
    );
  }

  render() {
    return(
      <MainContainerView>

        { this.buildBillingInfo() }

      </MainContainerView>
    );    
  }
}

////////// style

const MainContainerView = styled.div`
  display: flex;
  flex-direction: column;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  background-color: #1a202e;
  width: 100%;
  justify-content: center;
`;

const BillingContainerView = styled.div`
  height: auto;
  width: calc(50% - 20px);
  background-color: #35435D;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top-right-radius: 12px;
`;

const BillingTitleContainerView = styled.div`
  display: flex;
  margin-bottom: 8px;
  justify-content: space-between;
`;

const BillingTitleView = styled.div`
  color: white;
  font-size: 20px;
`;

const BillingInfoView = styled.div`
  color: white;
  font-size: 16px;
  margin-bottom: 2px;
`;

const EditView = styled.img`
  cursor: pointer;
`;

const PaymentTitle = styled.div`
  color: white;
  font-size: 18px;
  margin-left: 20px;
  margin-top: 30px;
  margin-bottom: 12px;
`;

const InfoTitle = styled.div`
  color: white;
  font-size: 16px;
  margin-left: 20px;
  margin-bottom: 2px;
`;

const BillingContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
`;

const RadioInputStyled = styled.div`
  margin-top: 3px;
  margin-left: 25px;
  & label {
    text-align: initial;
    color: white;
  }
`;

const BillingFormContainer = styled.div`
  padding-right: 20px;
  padding-left: 20px;
  margin-bottom: 20px;
`;

export default BillingDataView;