import React from "react";
import Button from "../../../shared/controls/button";
import { getPriceFormatNumber } from "../../../shared/utils";
import styled from "styled-components";
import ReactTooltip from "react-tooltip";
import Loader from "../../../shared/icons/tat/loader";
import OptionsList from '../../../shared/component/options-list';
import moment from 'moment';

class SubscriptionShoppingCarView extends React.Component {

  constructor() {
    super();

    this.state = {
      isCouponsOpen: false
    }
  }

  // Props que acepta el componente
  // {
  //   editable: boolean,
  //   itemsToBuy: [
  //     {
  //       id: Number,
  //       title: String,
  //       description: String,
  //       price: Number,
  //       quantity: Number,
  //     }
  //   ]
  // }

  
  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, true);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClickOutside, true);
  }

  handleClickOutside = event => {
    if (`${event.target.className}` !== 'option-list-component') {
      this.setState({isCouponsOpen: false});
    }
  }

  buildCouponsComponent = () => {
    return(
      <div
        style={{marginLeft: 50, fontSize: 13, display: 'flex', width: 300, alignItems: 'center', cursor: 'pointer', position: 'relative'}}
        onClick={() => this.setState({isCouponsOpen: !this.state.isCouponsOpen})}
      >
        <div style={{zIndex: 99999}}>
          <div style={{opacity: '59%'}}>
            {'Cupones:'}
          </div>
          <OptionsList
            width={'600px'}
            visible={this.state.isCouponsOpen}
            loading={false}
            items={(this.props.coupons || []).map( item => {
              return {
                item,
                component: 
                  <div className="option-list-component">
                    <div className="option-list-component">{`Cupón por haber recomendado a ${item.receiver_user?.username || ''} de la empresa ${item.company?.orbi_id || ''}`}</div>
                    <div className="option-list-component">{`Descuento por ${ item.discount * 100 }%`}</div>
                    <div className="option-list-component">{`vigente del ${item.validity_start ? moment(item.validity_start).format('DD/MMM/YYYY') : '---'} al ${moment(item.validity).format('DD/MMM/YYYY')}`}</div>
                  </div>
              }
            })}
            onClick={(value) => {
              if (this.props.onCouponSelect) {
                this.props.onCouponSelect(value);
              }
              this.setState({isCouponsOpen: false});
            }}
          />
        </div>

        <div
          style={{
            borderBottom: '2px solid rgba(255, 255, 255, 0.59)',
            height: 15,
            width: '100%',
            marginLeft: 8,
            marginRight: 8,
            textAlign: 'center',
            opacity: '59%'
          }}
        >
          {this.props.couponSelected ? `Cupón por ${(this.props.couponSelected && this.props.couponSelected.discount > 0) ? this.props.couponSelected.discount * 100 : 0}%` : ''}
        </div>
        {
          this.props.data ? '' :
          this.props.couponSelected &&
          <div
            onClick={(e) => {
              e.stopPropagation();
              if (this.props.onCouponSelect) {
                this.props.onCouponSelect(null);
              }
            }}
          >
            <img src="/images/close.svg" alt="" style={{width: 15, height: 15, cursor: 'pointer', marginLeft: 3, marginRight: 8}}/>
          </div>
        }
        {
          this.props.data ? '' :
          <img
            alt=""
            src="/images/select-arrow.svg"
            width={"15px"}
            height={"12px"}
          />
        }
      </div>
    );
  }



  buildTitle = () => {

    let exchange_rate = this.props.data ? (this.props.data.general_data?.exchange_rate || 0) : this.props.exchange_rate;

    return(
      <TitleView>
        <div>{'Carrito de compra'}
        </div>

        {this.buildCouponsComponent()}

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <div
            style={{
              fontSize: 13
            }}
          >{'TIPO DE CAMBIO USD'}</div>
          <div
            style={{
              fontSize: 17,
              backgroundColor: '#35435D',
              width: 'fit-content',
              paddingLeft: 8,
              paddingRight: 8,
              paddingTop: 3,
              paddingBottom: 3,
              marginTop: 4
            }}
          >{ `${getPriceFormatNumber(exchange_rate)}` }</div>
        </div>
        
      </TitleView>
    );
  }

  buildListHeader = () => {
    let isEditable = this.props.editable;

    let iva = this.props.data ? (this.props.data.general_data?.iva || 0) : this.props.iva;

    return(
      <HeaderContainerView>
        <ItemDescriptionContainerView
          isEditable={isEditable}
        />

        {
          isEditable ?
          this.buildQuantityHeader() : <></>
        }

        <QuantityItemContainer>
          {`IVA ${iva * 100}%`} 
        </QuantityItemContainer>

        {
          this.props.couponSelected &&
          <QuantityItemContainer>
            {`DCTO ${this.props.couponSelected.discount * 100}%`} 
          </QuantityItemContainer>
        }

        <ItemDescriptionPricePlanView
          isEditable={isEditable}
        />

        {
          this.props.data ? '' :
          <ItemDescriptionDeleteView
            isEditable={isEditable}
          />
        }

      </HeaderContainerView>
    );
  }

  buildQuantityHeader = () => {
    return(
      <>
        <QuantityItemContainer>
          {'QTY'}
        </QuantityItemContainer>

        <QuantityItemContainer>
          {'P.U. USD'}
        </QuantityItemContainer>

        <QuantityItemContainer>
          {`P.U. ${ this.props.data ? (this.props.data.general_data?.currency_code || '') : this.props.currency_code }`}
        </QuantityItemContainer>
      </>
    );
  }

  buildListOfItems = () => {

    let services = this.props.walletOrder ? this.props.walletOrder.movements : this.props.itemsToBuy || [];

    return(
      <ListOfItemsContainerView>
        {
          services.map((item, index) => {
            return(
              this.buildItem(item, index)
            );
          })
        }
      </ListOfItemsContainerView>
    );
  }

  deleteItem = (id) => {
    if (this.props.deleteItem) {
      this.props.deleteItem(id);
    }
  }

  buildItem = (item, index) => {
    let iva_value = this.props.data ? (this.props.data.general_data?.iva || 0) : this.props.iva;
    let exchange_rate = this.props.data ? (this.props.data.general_data?.exchange_rate || 0) : this.props.exchange_rate;

    let isEditable = this.props.editable;
    let first_price = 0;

    if (item.wholesale_price && item.wholesale_qty && item.quantity >= item.wholesale_qty) {
      first_price = item.wholesale_price;
    } else {
      first_price = item.unit_price;
    }

    let price_value = (item.applicable_coupon && this.props.couponSelected && this.props.couponSelected.discount > 0) ? (first_price * this.props.couponSelected.discount) * item.quantity : first_price * item.quantity;
    let iva = this.props.data ? (parseFloat(iva_value) * parseFloat(item.price_list_usd) * exchange_rate) : (iva_value * first_price) * exchange_rate;
    let discount = (item.applicable_coupon && this.props.couponSelected && this.props.couponSelected.discount > 0) ? this.props.couponSelected.discount * first_price : 0;

    let subtotal = this.props.data ? parseFloat(item.subtotal) : price_value * exchange_rate;
    let discount_value = this.props.data ? parseFloat(item.amount_payment) : this.props.couponSelected ? discount * exchange_rate : 0;

    return(
      <ItemContainerView
        key={`item-to-buy-${index}`}
      >

        <ItemDescriptionContainerView
          isEditable={isEditable}
        >
          <ItemDescriptionNameView>{`${ this.props.data && item.data ? item.data.package_name || '' : item.title}`}</ItemDescriptionNameView>
          <ItemDescriptionPlanDescriptionView>{item.description}</ItemDescriptionPlanDescriptionView>
        </ItemDescriptionContainerView>

        {
          isEditable ?
          this.buildQuantityItem(item) : <></>
        }

        <QuantityItemContainer>
          {`${getPriceFormatNumber(iva)}`}
        </QuantityItemContainer>

        {
          this.props.couponSelected &&
          <QuantityItemContainer>
            {`${getPriceFormatNumber(discount_value)}`}
          </QuantityItemContainer>
        }

        <ItemDescriptionPricePlanView
          isEditable={isEditable}
        >
          {`${getPriceFormatNumber(subtotal)}`}
        </ItemDescriptionPricePlanView>
        
        {
          this.props.data ? '' : 
          <ItemDescriptionDeleteView
            isEditable={isEditable}
          >
            <img 
              alt='delete' 
              src='/images/delete.svg'
              style={{
                cursor: 'pointer'
              }}
              onClick={ () => { this.deleteItem(item.id) }}/>
          </ItemDescriptionDeleteView>
        }

      </ItemContainerView>
    );
  }

  validEditable = (e, id, validation, min_qty) => {

    let newValue = e.target.value;

    if (parseInt(newValue) < min_qty && validation) {
      newValue = min_qty;
    }

    let items = this.props.itemsToBuy || [];

    items.map( (item, index) => {
      if (item.id === id) {
        items[index].quantity = newValue;
      }
    })

    this.props.changeEditable(items);
  };

  buildQuantityItem = (item) => {

    let unit_price = this.props.data ? parseFloat(item.price_list_usd) : item.unit_price;

    if (this.props.data) {
      unit_price = parseFloat(item.price_list_usd);
    } else {
      if (item.wholesale_price && item.wholesale_qty && item.quantity >= item.wholesale_qty) {
        unit_price = item.wholesale_price;
      } else {
        unit_price = item.unit_price;
      }
    }

    let amount = this.props.data ? parseFloat(item.amount) : unit_price * this.props.exchange_rate;

    return(
      <>
        <QuantityItemContainer>
          <QuantityItemInput
            name="editable"
            type="number"
            readOnly={!item.editable}
            value={item.quantity}
            onChange={(e) => {
              if (item.editable)
                this.validEditable(e, item.id, false, item.min_qty);
            }}
            onBlur={(e) => {
              if (item.editable)
                this.validEditable(e, item.id, true, item.min_qty);
            }}
          />
        </QuantityItemContainer>

        <QuantityItemContainer>
          <QuantityItemPrice>
            {`${getPriceFormatNumber(unit_price)}`}
          </QuantityItemPrice>
        </QuantityItemContainer>

        <QuantityItemContainer>
          <QuantityItemPrice>
            {`${getPriceFormatNumber(amount)}`}
          </QuantityItemPrice>
        </QuantityItemContainer>
      </>
    );
  }

  buildBuyInfo = () => {

    let services = this.props.itemsToBuy || [];
    let services_to_buy = [];
    let subtotal = 0;
    let iva = 0;
    let total = 0;

    if (this.props.data) {

      subtotal = this.props.walletOrder ? parseFloat(this.props.walletOrder.subtotal) : 0;
      iva = this.props.walletOrder ? parseFloat(this.props.walletOrder.vat) : 0;
      total = this.props.walletOrder ? parseFloat(this.props.walletOrder.total) : 0;

    } else {

      let price_value = services.map(item => {

        let first_price = 0;

        if (item.wholesale_price && item.wholesale_qty && item.quantity >= item.wholesale_qty) {
          first_price = item.wholesale_price;
        } else {
          first_price = item.unit_price;
        }

        if (item.applicable_coupon && this.props.couponSelected && this.props.couponSelected.discount > 0) {
          return (first_price * this.props.couponSelected.discount) * item.quantity
        }
  
        return first_price * item.quantity
      }).reduce((a, b) => a + b, 0);
      let iva_value = services.map(item => {

        let first_price = 0;

        if (item.wholesale_price && item.wholesale_qty && item.quantity >= item.wholesale_qty) {
          first_price = item.wholesale_price;
        } else {
          first_price = item.unit_price;
        }

        if (item.applicable_coupon && this.props.couponSelected && this.props.couponSelected.discount > 0) {
          return this.props.iva * ((first_price * this.props.couponSelected.discount) * item.quantity);
        }
  
        return this.props.iva * (first_price * item.quantity);
      }).reduce((a, b) => a + b, 0);

      subtotal = price_value * this.props.exchange_rate;
      iva = iva_value * this.props.exchange_rate;
      total = (price_value + iva_value) * this.props.exchange_rate;
  
      services_to_buy = services.map(item => {
  
        let service_temp = {
          package_id: item.id,
          quantity: parseInt(item.quantity),
          package_name: item.title
        };
  
        // if (item.applicable_coupon && this.props.couponSelected) {
        //   service_temp.coupon_id = this.props.couponSelected.id;
        // }
  
        return service_temp;
      });

    }

    return(
      <BuyInfoContainerView>

        <BuyInfoPriceContainerView>
          <BuyInfoPriceTotalView>{'SubTotal'}</BuyInfoPriceTotalView>
          <BuyInfoPriceView>{`${getPriceFormatNumber(subtotal)} ${this.props.data ? (this.props.data.general_data?.currency_code || '') : this.props.currency_code}`}</BuyInfoPriceView>
        </BuyInfoPriceContainerView>

        <BuyInfoPriceContainerView
          style={{ marginTop: -15 }}
        >
          <div style={{fontSize: 18}}>{'IVA'}</div>
          <div style={{fontSize: 18}}>{`${getPriceFormatNumber(iva)} ${this.props.data ? (this.props.data.general_data?.currency_code || '') : this.props.currency_code}`}</div>
        </BuyInfoPriceContainerView>

        <DividerView/>

        <BuyInfoPriceContainerView>
          <BuyInfoPriceTotalView>{'Total'}</BuyInfoPriceTotalView>
          <BuyInfoPriceView>{`${getPriceFormatNumber(total)} ${this.props.data ? (this.props.data.general_data?.currency_code || '') : this.props.currency_code}`}</BuyInfoPriceView>
        </BuyInfoPriceContainerView>

        {
          this.props.content && this.props.content
        }

        {
          this.props.data ? '' :
          <ButtonContainer>
            <ReactTooltip
              id={"tooltip-error-invoice_data"}
              place="top"
              type="light"
              effect="solid"
              html={true}
              event="mouseover"
              eventOff="mouseleave"
            />
            <Button
              text={this.props.isLoading ? <Loader/> : "Pagar"}
              type="primary"
              disabled={(this.props.itemsToBuy === null) || (this.props.itemsToBuy.length === 0) || this.props.isLoading ||(this.props.buyButtonDisabled) || (this.props.errors || '') !== ""}
              onClick={() => {
                if (this.props.buyAction) {
                  this.props.buyAction({
                    items: services_to_buy
                  });
                }
              }}
              dataTip={this.props.errors}
              dataFor="tooltip-error-invoice_data"
            />
          </ButtonContainer>
        }

      </BuyInfoContainerView>
    );
  }

  render() {
    let isEditable = this.props.editable;

    return(
      <MainContainerView
        isEditable={isEditable}
      >
        { this.buildTitle() }
        { this.buildListHeader() }
        { this.buildListOfItems() }
        { this.buildBuyInfo() }
      </MainContainerView>
    );
  }

}

export default SubscriptionShoppingCarView;

////////// style

const MainContainerView: ComponentType<{ isEditable: boolean }>= styled.div`
  background-color: #1a202e;
  width: 100%;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
`;

const TitleView = styled.div`
  color: white;
  font-size: 20px;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 60px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
`;

const ListOfItemsContainerView = styled.div`
  border-bottom-color: white;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  max-height: 350px;
  overflow-y: auto;
  padding-left: 20px;
  padding-right: 20px;
`;

const ItemContainerView = styled.div`
  display: flex;
  padding-bottom: 10px;
  padding-top: 10px;
`;

const ItemDescriptionContainerView: ComponentType<{ isEditable: boolean }> = styled.div`
  width: ${({isEditable}) => (isEditable ? '24%' : '50%')};
  color: white;
`;

const ItemDescriptionNameView = styled.div`
  font-size: 15px;
  opacity: 65%;
`;

const ItemDescriptionPlanDescriptionView = styled.div`
  font-size: 14px;
  margin-top: 4px;
`;

const ItemDescriptionPricePlanView: ComponentType<{ isEditable: boolean }> = styled.div`
  width: ${({isEditable}) => (isEditable ? '16%' : '30%')};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 12px;
`;

const ItemDescriptionDeleteView: ComponentType<{ isEditable: boolean }> = styled.div`
  width: ${({isEditable}) => (isEditable ? '10%' : '20%')};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BuyInfoContainerView = styled.div`
  display: flex;
  flex-direction: column;
`;

const BuyInfoPriceContainerView = styled.div`
  color: white;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-left: 20px;
  padding-right: 20px;
`;

const BuyInfoPriceTotalView = styled.div`
  font-size: 16px;
`;

const BuyInfoPriceView = styled.div`
  font-size: 18px;
`;

const QuantityItemContainer = styled.div`
  width: 16%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  opacity: 59%;
  color: white;
  font-size: 12px;
  text-align: center;
`;

const QuantityItemTitle = styled.div`
  position: absolute;
  top: 0px;
  font-size: 16px;
  color: white;
  opacity: 59%;
`;

const QuantityItemPrice = styled.div`
  color: white;
  font-size: 12px;
  text-align: center;
`;

const QuantityItemInput = styled.input`
  align-items: center;
  background: #6a81ad;
  border: none;
  width: 70%;
  height: 30px;
  color: #ffffff;
  font-weight: bolder;
  text-align: center;
  font-size: 12px;
  border-bottom: 2px solid #35315a;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

const HeaderContainerView = styled.div`
  border-bottom-color: white;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  display: flex;
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 20px;
  padding-right: 20px;
`;

const DividerView = styled.div`
  border-bottom-color: white;
  border-bottom-style: solid;
  border-bottom-width: 1px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-right: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
`;