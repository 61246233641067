import React from "react";
import { PurchaseContainer } from "./style";
import Button from "../../../shared/controls/button";
import ReactTooltip from "react-tooltip";

class PaymentsPurchase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { credits } = this.props.data;
    return (
      <PurchaseContainer>
        <div>SALDO AL DIA DE HOY</div>
        <div className="info">
          <img src="/images/orbicoin.png" alt=""/>
          <div className="qty">
            <span className="money">{credits}</span>
            <span>ORBIBUCKS</span>
          </div>
          <Button 
            disabled={this.props.errorCompanyVerification !== ''}
            dataTip={this.props.errorCompanyVerification}
            dataFor="purchase-tooltip-error"
            text="Comprar saldo" 
            type="primary" 
            onClick={() => {
              if (this.props.openBuyOrbibucksAction) {
                this.props.openBuyOrbibucksAction();
              }
            }}
          />
          <ReactTooltip
            id={"purchase-tooltip-error"}
            place="bottom"
            type="light"
            effect="solid"
            html={true}
            event="mouseover"
            eventOff="mouseleave"
          />
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row-reverse'
          }}
        >
          <div 
            style={{
              width: 'fit-content',
              cursor: 'pointer',
              textDecoration: 'underline'
            }}
            onClick={() => {
              if (this.props.openCouponModal) {
                this.props.openCouponModal();
              }
            }}
          >{`${this.props.coupons.filter(item => (item.status?.id || 0) === 2).length} cupones`}</div>

        </div>

        </PurchaseContainer>
    );
  }
}

export default PaymentsPurchase;
