import React from "react";
import { HistoryContainer, TooltipContainer } from "./style";
import Loader from "../../../shared/icons/tat/loader";
import ReactTooltip from "react-tooltip";
import { getUrlsEnv, openSecureDoc } from "../../../shared/backend-api";
import PDFIcon from "../../../shared/icons/pdf-icon";
import XMLIcon from "../../../shared/icons/xml-icon";

class PaymentsHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hoverId: null
    };
  }

  componentDidMount() {
    document.addEventListener('page-content-scroll', this.handlePageContentScroll);
    document.addEventListener('mousedown', this.handlePageContentScroll, false);
  }

  componentWillUnmount() {
    document.removeEventListener('page-content-scroll', this.handlePageContentScroll);
    document.removeEventListener('mousedown', this.handlePageContentScroll, false);
  }

  handlePageContentScroll = (e) => {
    if (e && e.target && e.target.id && (e.target.id.includes('download-document') || e.target.id.includes('see-document'))) {
      return;
    }
    ReactTooltip.hide();
  }

  isHover = (id) => {
    return this.state.hoverId === id;
  }

  downloadDocument = (url) => {
    openSecureDoc(getUrlsEnv().files.concat(url)).then((response) => {
      var array = url.split("/");
      var lastsegment = array.length > 0 ? array[array.length - 1] : ".";

      response.blob().then((blob) => {
        let url = window.URL.createObjectURL(blob);

        let a = document.createElement("a");
        a.href = url;
        a.download = lastsegment;
        a.click();
      });
    });
  };

  showDocument = (url) => {
    window.open(getUrlsEnv().files.concat(url), "_blank");
  };

  render() {
    const { movements, total, details } = this.props.data;
    return (
      <HistoryContainer>
        <div className="title">
          <div>Historial de compras</div>
          <div>{movements} ordenes</div>
        </div>
        <div 
          className="details"
          onScroll={e => {
            ReactTooltip.hide();
          }}
        >
          {details.map((item) => {
            return (
              <div
                className="row-container"
                key={`row-container-${item.id}`}
                onClick={() => {
                  if (this.props.openOrder) {
                    this.props.openOrder(item.id);
                  }
                }}
                onMouseEnter={() => this.setState({hoverId: item.id})} 
                onMouseLeave={() => this.setState({hoverId: null})}
              >
                <div className={`row ${this.isHover(item.id) ? 'hovered' : ''}`} key={`history-row-${item.id}`}>
                  <div className="section">{`${item.order}`}</div>
                  <div className="section">{`${item.status}`}</div>
                  <div className="section">{`${item.transaction}`}</div>

                  <div className="section-2">
                  {
                    (this.props.isLoadingCancelling && item.order_status === 1) ?
                    <Loader width='15px' height='15px'/>
                    :
                    item.order_status === 1 ?
                    <img
                      alt="" 
                      src="/images/delete_icon.png"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (this.props.cancelOrder)
                          this.props.cancelOrder(item) 
                      }}
                      style={{cursor: 'pointer', height: 17, width: 15}}/> : <div style={{height: 17, width: 15}}/>
                  }
                  </div>
                </div>
                <div 
                  className={`dates-section ${this.isHover(item.id) ? 'hovered' : ''}`}
                >
                  <div className="creation-date" >{`Creado ${item.date}`}</div>
                  <div className="creation-date" >{`${item.description_date}`}</div>
                  <div className="creation-date" >{`${item.description_discount}`}</div>
                  <div style={{height: 17, width: 15}}/>
                </div>
                <div className="files-section">

                  {
                    (item.pdf_order || '') !== '' &&
                    <>
                      <div 
                        className="icon"
                        data-tip
                        data-for={`order-icon-${item.id}`}
                        onMouseEnter={(e) => {
                          ReactTooltip.hide();
                          ReactTooltip.show(e.currentTarget);
                        }}
                      >
                        <PDFIcon
                          height="15" 
                          fill={'white'}
                        />
                        <div>Orden</div>
                      </div>

                      <ReactTooltip
                        id={`order-icon-${item.id}`}
                        delayHide={20}
                        delayShow={20}
                        delayUpdate={200}
                        place={"bottom"}
                        border={true}
                        type={"light"}
                        effect="solid"
                        clickable={true}
                        event={'click'}
                        eventOff={'click'}
                      >
                        <TooltipContainer>
                          <div 
                            className="option"
                            id={"see-document"}
                            onClick={(e) => {
                              ReactTooltip.hide();
                              e.stopPropagation(); 

                              this.showDocument(item.pdf_order.substring(1));
                            }}
                          >
                            {`Ver archivo`}
                          </div>
                          <div
                            className="option"
                            id={"download-document"}
                            onClick={(e) => {
                              ReactTooltip.hide();
                              e.stopPropagation(); 

                              this.downloadDocument(item.pdf_order.substring(1));
                            }}
                          >
                            {`Descargar archivo`}
                          </div>
                        </TooltipContainer>
                      </ReactTooltip>
                    </>
                  }

                  {
                    (item.pdf_receipt || '') !== '' &&
                    <>
                      <div 
                        className="icon"
                        data-tip
                        data-for={`receipt-icon-${item.id}`}
                        onMouseEnter={(e) => {
                          ReactTooltip.hide();
                          ReactTooltip.show(e.currentTarget);
                        }}
                      >
                        <PDFIcon
                          height="15" 
                          fill={'white'}
                        />
                        <div>Recibo</div>
                      </div>

                      <ReactTooltip
                        id={`receipt-icon-${item.id}`}
                        delayHide={20}
                        delayShow={20}
                        delayUpdate={200}
                        place={"bottom"}
                        border={true}
                        type={"light"}
                        effect="solid"
                        clickable={true}
                        event={'click'}
                        eventOff={'click'}
                      >
                        <TooltipContainer>
                          <div 
                            className="option"
                            id={"see-document"}
                            onClick={(e) => {
                              ReactTooltip.hide();
                              e.stopPropagation(); 

                              this.showDocument(item.pdf_receipt.substring(1));
                            }}
                          >
                            {`Ver archivo`}
                          </div>
                          <div
                            className="option"
                            id={"download-document"}
                            onClick={(e) => {
                              ReactTooltip.hide();
                              e.stopPropagation(); 

                              this.downloadDocument(item.pdf_receipt.substring(1));
                            }}
                          >
                            {`Descargar archivo`}
                          </div>
                        </TooltipContainer>
                      </ReactTooltip>
                    </>
                  }

                  {
                    (item.pdf_invoice || '') !== '' &&
                    <>
                      <div 
                        className="icon"
                        data-tip
                        data-for={`invoice-icon-${item.id}`}
                        onMouseEnter={(e) => {
                          ReactTooltip.hide();
                          ReactTooltip.show(e.currentTarget);
                        }}
                      >
                        <PDFIcon
                          height="15" 
                          fill={'#3d77f7'}
                        />
                        <div>Factura pdf</div>
                      </div>

                      <ReactTooltip
                        id={`invoice-icon-${item.id}`}
                        delayHide={20}
                        delayShow={20}
                        delayUpdate={200}
                        place={"bottom"}
                        border={true}
                        type={"light"}
                        effect="solid"
                        clickable={true}
                        event={'click'}
                        eventOff={'click'}
                      >
                        <TooltipContainer>
                          <div 
                            className="option"
                            id={"see-document"}
                            onClick={(e) => {
                              ReactTooltip.hide();
                              e.stopPropagation(); 

                              this.showDocument(item.pdf_invoice.substring(1));
                            }}
                          >
                            {`Ver archivo`}
                          </div>
                          <div
                            className="option"
                            id={"download-document"}
                            onClick={(e) => {
                              ReactTooltip.hide();
                              e.stopPropagation(); 

                              this.downloadDocument(item.pdf_invoice.substring(1));
                            }}
                          >
                            {`Descargar archivo`}
                          </div>
                        </TooltipContainer>
                      </ReactTooltip>
                    </>
                  }

                  {
                    (item.xml_invoice || '') !== '' &&
                    <>
                      <div 
                        className="icon"
                        data-tip
                        data-for={`xml_invoice-icon-${item.id}`}
                        onMouseEnter={(e) => {
                          ReactTooltip.hide();
                          ReactTooltip.show(e.currentTarget);
                        }}
                      >
                        <XMLIcon
                          height="15" 
                          fill={'#3d77f7'}
                        />
                        <div>Factura xml</div>
                      </div>

                      <ReactTooltip
                        id={`xml_invoice-icon-${item.id}`}
                        delayHide={20}
                        delayShow={20}
                        delayUpdate={200}
                        place={"bottom"}
                        border={true}
                        type={"light"}
                        effect="solid"
                        clickable={true}
                        event={'click'}
                        eventOff={'click'}
                      >
                        <TooltipContainer>
                          <div 
                            className="option"
                            id={"see-document"}
                            onClick={(e) => {
                              ReactTooltip.hide();
                              e.stopPropagation(); 

                              this.showDocument(item.xml_invoice.substring(1));
                            }}
                          >
                            {`Ver archivo`}
                          </div>
                          <div
                            className="option"
                            id={"download-document"}
                            onClick={(e) => {
                              ReactTooltip.hide();
                              e.stopPropagation(); 

                              this.downloadDocument(item.xml_invoice.substring(1));
                            }}
                          >
                            {`Descargar archivo`}
                          </div>
                        </TooltipContainer>
                      </ReactTooltip>
                    </>
                  }
                </div>
              </div>
            );
          })}
        </div>
        <div className="footer">
          <div className="qty">
            Total de compras <span>{total}</span>
          </div>
        </div>
      </HistoryContainer>
    );
  }
}

export default PaymentsHistory;
