import React, { createRef, useRef } from "react";
import TextInput from "../shared/controls/text-input";
import Button from "../shared/controls/button";
import Checkbox from "../shared/controls/checkbox";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { createEntity, getEntityItems, partialEntity, readEntities } from "../redux/api";
import { connect } from "react-redux";
import { validateEmail } from "../shared/validate";
import Loader from "../shared/icons/tat/loader";
import ConfirmDialog from "../shared/dialog/confirm-dialog";
import { cancelMultipleRequests } from "../redux/api/actions";
import OptionsList from "../shared/component/options-list";
import { 
  MainContent,
  AgentContainer,
  CheckboxContainerLevel2,
  CheckboxContainerLevel1,
  BackButton,
  BackContainer,
  ImgContainer,
  ImgQuestionSmall,
  ImgQuestionMedium,
  ImgQuestion,
  RadioContainer,
  RadioItem,
  Separator,
  TextArea,
  ProgressBar,
  ProgressBarContainer,
  BottomBlankSpace,
  BottomContainer,
  SelectorImgContainerSmall,
  SelectorImgContainer,
  SelectorTitleMedium,
  SelectorTitleHorizontal,
  SelectorTitle,
  SelectorBorderHorizontalSmall,
  SelectorBorderSmall,
  SelectorBorder,
  SelectorContainerHorizontal,
  SelectorContainer,
  ImgSubtitle,
  FieldSubtitle,
  FieldTitle
} from "./style/view";

const scrollRef = createRef()

class RegisterNewClientView extends React.Component {

  state = {
    name: '',
    email: '',
    company_name: '',
    mobile_phone: '',
    contact_type: null,
    industry_type: null,
    trips_received: null,
    trips_ejecuted: null,
    trips_ejecuted_t2: null,
    trips_ejecuted_last_mile: null,
    role: '',
    faculties_in_organization: [],
    productivity_selected: [],
    digital_transformation_selected: [],
    security_selected: [],
    distribution_and_logistics_selected: [],
    nps_selected: [],
    order_items: [],
    other_problems: '',
    supply_chain_performance: 0,
    digital_transformation_item: null,
    digital_transformation_process_or_adoption: null,
    time_in_process: null,
    budget_invested_in_your_project: null,
    things_required_to_start_a_project: '',
    wish_in_supply_chain: '',
    budget_required_project: null,
    benefits_to_your_company_in_orbi: '',
    who_else_involved_in_your_company: '',
    is_someone_attending: null,
    is_someone_attending_name: '',
    currentStep: 1,
    allSteps: 17,
    client: null,
    agent: null,
    error: '',
    booked: false
  }

  componentDidMount() {
    if (this.props.location.search.includes('booked')) {
      const query = new URLSearchParams(this.props.location.search);
      this.state.booked = query.get('booked') === 'true';
    }
  }

  handleCheckboxChange = (e) => {
    const { name, value, checked } = e.target;
    this.setState({[name]: checked});
  }

  handleChange = (e) => {
    const { name, value } = e.target;

    if (name === 'mobile_phone' && value.length > 10) {
      return;
    }

    if ((name === 'role' || name === 'name' || name === 'company_name') && value.length > 200) {
      return;
    }

    if ((name === 'other_problems' || 
      name === 'things_required_to_start_a_project' || 
      name === 'wish_in_supply_chain' || 
      name === 'benefits_to_your_company_in_orbi' || 
      name === 'who_else_involved_in_your_company') && value.length > 400) {
      return;
    }

    this.setState({[name]: value});
  }

  handleSteps = (isMobile) => {

    switch(this.state.currentStep) {
      case 1: return this.buildStep1(isMobile);
      case 2: return this.buildStep2(isMobile);
      case 3: return this.buildStep3(isMobile);
      case 4: return this.buildStep4(isMobile);
      case 5: return this.buildStep5(isMobile);
      case 6: return this.buildStep6(isMobile);
      case 7: return this.buildStep7(isMobile);
      case 8: 
        return (
          <>
            { this.buildStep8_1(isMobile) }
            { this.buildStep8_2(isMobile) }
            { this.buildStep8_3(isMobile) }
            { this.buildStep8_4(isMobile) }
            { this.buildStep8_5(isMobile) }
            { this.buildStep8_6(isMobile) }
          </>
        );
      case 9:
        const isTouchScreen = HTML5Backend;
        return this.buildStep9(isTouchScreen, isMobile);
      case 10: return this.buildStep10(isMobile);
      case 11: return this.buildStep11(isMobile);
      case 12: return this.buildStep12(isMobile);
      case 13: return this.buildStep13(isMobile);
      case 14: return this.buildStep14(isMobile);
      case 15: return this.buildStep15(isMobile);
      case 16: return this.buildStep16(isMobile);
      case 17: return this.buildStep17(isMobile);
      default: return <></>
    }
  }

  moveCard = (dragIndex, hoverIndex) => { 
    let data = [...this.state.order_items];
          
    let temp = data[dragIndex];
    data[dragIndex] = data[hoverIndex];
    data[hoverIndex] = temp;

    this.setState({
      order_items: data
    });
  }

  getStep16OptionImage = (id) => {
    switch(id) {
      case 1: return this.state.contact_type !== null && this.state.contact_type === id ? "/images/new_client_co/iconos/Pag 16 Respuesta A act.svg" : "/images/new_client_co/iconos/Pag 16 Respuesta A ina.svg"
      case 2: return this.state.contact_type !== null && this.state.contact_type === id ? "/images/new_client_co/iconos/Pag 16 Respuesta B act.svg" : "/images/new_client_co/iconos/Pag 16 Respuesta B ina.svg"
      case 3: return this.state.contact_type !== null && this.state.contact_type === id ? "/images/new_client_co/iconos/Pag 16 Respuesta C act.svg" : "/images/new_client_co/iconos/Pag 16 Respuesta C ina.svg"
    }

    return "/images/new_client_co/iconos/Pag 16 Respuesta A act.svg";
  }

  getStep16Options = (isMobile) => {

    let _ = require("lodash");
    let arr = _.chunk((this.props.contact_type || []), 3);

    return(
      <>
        {
          arr.map( (main_array, main_array_index) => {
            return(
              <SelectorContainer
                key={`main_array_${main_array_index}`}
              >
                {
                  main_array.map( item => {
                    return(
                      <SelectorBorder
                        key={`item_array_${main_array_index}_${item.id}`}
                        isMobile={isMobile}
                        onClick={() => {
                          this.setState({
                            contact_type: this.state.contact_type === null || this.state.contact_type !== item.id ? item.id : null
                          })
                        }}
                      >
                        <SelectorImgContainer 
                          key={`item_container_${main_array_index}_${item.id}`}
                          alt="" 
                          src={ this.getStep16OptionImage(item.id) }/>
                          
                        <SelectorTitle
                          key={`item_title_${main_array_index}_${item.id}`}
                        >{item.description}</SelectorTitle>
                      </SelectorBorder>
                    )
                  })
                }
              </SelectorContainer>
            );
          })
        }
      </>
    );

  }

  buildStep1 = (isMobile) => {
    return(
      <>
        <div>
          <TextInput
            type='text'
            label="Nombre completo (máximo 200 caracteres)"
            name="name"
            value={this.state.name}
            className="item"
            onChange={this.handleChange}
          />
        </div>

        <div>
          <TextInput
            type='text'
            label="Empresa (máximo 200 caracteres)"
            name="company_name"
            value={this.state.company_name}
            className="item"
            onChange={this.handleChange}
          />
        </div>
      </>
    );
  }

  getStep2OptionImage = (id) => {
    switch(id) {
      case 1: return this.state.industry_type !== null && this.state.industry_type === id ? "/images/new_client_co/iconos/Pag 2 Respuesta A act.svg" : "/images/new_client_co/iconos/Pag 2 Respuesta A ina.svg"
      case 2: return this.state.industry_type !== null && this.state.industry_type === id ? "/images/new_client_co/iconos/Pag 2 Respuesta B act.svg" : "/images/new_client_co/iconos/Pag 2 Respuesta B ina.svg"
      case 3: return this.state.industry_type !== null && this.state.industry_type === id ? "/images/new_client_co/iconos/Pag 2 Respuesta C act.svg" : "/images/new_client_co/iconos/Pag 2 Respuesta C ina.svg"
      case 4: return this.state.industry_type !== null && this.state.industry_type === id ? "/images/new_client_co/iconos/Pag 2 Respuesta D act.svg" : "/images/new_client_co/iconos/Pag 2 Respuesta D ina.svg"
      case 5: return this.state.industry_type !== null && this.state.industry_type === id ? "/images/new_client_co/iconos/Pag 2 Respuesta I act.svg" : "/images/new_client_co/iconos/Pag 2 Respuesta I ina.svg"
      case 6: return this.state.industry_type !== null && this.state.industry_type === id ? "/images/new_client_co/iconos/Pag 2 Respuesta F act.svg" : "/images/new_client_co/iconos/Pag 2 Respuesta F ina.svg"
      case 7: return this.state.industry_type !== null && this.state.industry_type === id ? "/images/new_client_co/iconos/Pag 2 Respuesta G act.svg" : "/images/new_client_co/iconos/Pag 2 Respuesta G ina.svg"
      case 8: return this.state.industry_type !== null && this.state.industry_type === id ? "/images/new_client_co/iconos/Pag 2 Respuesta H act.svg" : "/images/new_client_co/iconos/Pag 2 Respuesta H ina.svg"
      case 9: return this.state.industry_type !== null && this.state.industry_type === id ? "/images/new_client_co/iconos/Pag 2 Respuesta E act.svg" : "/images/new_client_co/iconos/Pag 2 Respuesta E ina.svg"
      case 10: return this.state.industry_type !== null && this.state.industry_type === id ? "/images/new_client_co/iconos/Pag 2 Respuesta J act.svg" : "/images/new_client_co/iconos/Pag 2 Respuesta J ina.svg"
    }

    return "/images/new_client_co/iconos/Pag 2 Respuesta A act.svg";
  }

  getStep2Options = (isMobile) => {

    let _ = require("lodash");
    let arr = _.chunk((this.props.industry_type_catalog || []), 3);
    
    return(
      <>
        {
          arr.map( (main_array, main_array_index) => {
            return(
              <SelectorContainer
                key={`main_array_${main_array_index}`}
              >
                {
                  main_array.map( item => {
                    return(
                      <SelectorBorder
                        key={`item_array_${main_array_index}_${item.id}`}
                        isMobile={isMobile}
                        onClick={() => {
                          this.setState({
                            industry_type: this.state.industry_type === null || this.state.industry_type !== item.id ? item.id : null
                          })
                        }}
                      >
                        <SelectorImgContainer 
                          key={`item_container_${main_array_index}_${item.id}`}
                          alt="" 
                          src={ this.getStep2OptionImage(item.id) }/>
                          
                        <SelectorTitle
                          key={`item_title_${main_array_index}_${item.id}`}
                        >{item.description}</SelectorTitle>
                      </SelectorBorder>
                    )
                  })
                }
              </SelectorContainer>
            );
          })
        }
      </>
    );
  }

  buildStep2 = (isMobile) => {
    return(
      <>
        <FieldTitle
          isMobile={isMobile}
        >{'Selecciona tu industria:'}</FieldTitle>

        {
          this.getStep2Options(isMobile)
        }
      </>
    );
  }

  getStep3OptionImage = (id) => {
    switch(id) {
      case 1: return this.state.trips_received !== null && this.state.trips_received === id ? "/images/new_client_co/iconos/Pag 3-6 Respuesta 1 act.svg" : "/images/new_client_co/iconos/Pag 3-6 Respuesta 1 ina.svg"
      case 2: return this.state.trips_received !== null && this.state.trips_received === id ? "/images/new_client_co/iconos/Pag 3-6 Respuesta 2 act.svg" : "/images/new_client_co/iconos/Pag 3-6 Respuesta 2 ina.svg"
      case 3: return this.state.trips_received !== null && this.state.trips_received === id ? "/images/new_client_co/iconos/Pag 3-6 Respuesta 3 act.svg" : "/images/new_client_co/iconos/Pag 3-6 Respuesta 3 ina.svg"
      case 4: return this.state.trips_received !== null && this.state.trips_received === id ? "/images/new_client_co/iconos/Pag 3-6 Respuesta 4 act.svg" : "/images/new_client_co/iconos/Pag 3-6 Respuesta 4 ina.svg"
    }

    return "/images/new_client_co/iconos/Pag 3-6 Respuesta 1 act.svg";
  }

  getStep3Options = (isMobile) => {

    let _ = require("lodash");
    let arr = _.chunk((this.props.primary_distribution_t1 || []), 4);
    
    return(
      <>
        {
          arr.map( (main_array, main_array_index) => {
            return(
              <SelectorContainer
                key={`main_array_${main_array_index}`}
              >
                {
                  main_array.map( item => {
                    return(
                      <SelectorBorder
                        key={`item_array_${main_array_index}_${item.id}`}
                        isMobile={isMobile}
                        onClick={() => {
                          this.setState({
                            trips_received: this.state.trips_received === null || this.state.trips_received !== item.id ? item.id : null
                          })
                        }}
                      >
                        <SelectorImgContainer 
                          key={`item_container_${main_array_index}_${item.id}`}
                          alt="" 
                          src={ this.getStep3OptionImage(item.id) }/>
                          
                        <SelectorTitle
                          key={`item_title_${main_array_index}_${item.id}`}
                        >{item.description}</SelectorTitle>
                      </SelectorBorder>
                    )
                  })
                }
              </SelectorContainer>
            );
          })
        }
      </>
    );
  }

  buildStep3 = (isMobile) => {
    return(
      <>
        <ImgContainer>
          <ImgQuestion
            alt="" 
            isMobile={isMobile}
            src={ this.state.trips_received !== null ? "/images/new_client_co/iconos/Pag 3 Pregunta act.svg" : "/images/new_client_co/iconos/Pag 3 Pregunta ina.svg"}/>
        </ImgContainer>
        <FieldTitle
          isMobile={isMobile}
        >
          {'Hablemos de operaciones '}
          <span class='underline'>{'mensuales'}</span>
        </FieldTitle>
        <FieldTitle
          isMobile={isMobile}
        >
          {'¿Cuántos camiones completos recibes en distribución '}
          <span class='underline'>{'primaria inbound'}</span>
          {'? '}
          <br/>
          <span class='medium'>{'de tus proveedores a tus fábricas/cedis:'}</span>
        </FieldTitle>

        { this.getStep3Options(isMobile) }

      </>
    );
  }

  getStep4OptionImage = (id) => {
    switch(id) {
      case 1: return this.state.trips_ejecuted !== null && this.state.trips_ejecuted === id ? "/images/new_client_co/iconos/Pag 3-6 Respuesta 1 act.svg" : "/images/new_client_co/iconos/Pag 3-6 Respuesta 1 ina.svg"
      case 2: return this.state.trips_ejecuted !== null && this.state.trips_ejecuted === id ? "/images/new_client_co/iconos/Pag 3-6 Respuesta 2 act.svg" : "/images/new_client_co/iconos/Pag 3-6 Respuesta 2 ina.svg"
      case 3: return this.state.trips_ejecuted !== null && this.state.trips_ejecuted === id ? "/images/new_client_co/iconos/Pag 3-6 Respuesta 3 act.svg" : "/images/new_client_co/iconos/Pag 3-6 Respuesta 3 ina.svg"
      case 4: return this.state.trips_ejecuted !== null && this.state.trips_ejecuted === id ? "/images/new_client_co/iconos/Pag 3-6 Respuesta 4 act.svg" : "/images/new_client_co/iconos/Pag 3-6 Respuesta 4 ina.svg"
    }

    return "/images/new_client_co/iconos/Pag 3-6 Respuesta 1 act.svg";
  }

  getStep4Options = (isMobile) => {

    let _ = require("lodash");
    let arr = _.chunk((this.props.primary_distribution_t1 || []), 4);
    
    return(
      <>
        {
          arr.map( (main_array, main_array_index) => {
            return(
              <SelectorContainer
                key={`main_array_${main_array_index}`}
              >
                {
                  main_array.map( item => {
                    return(
                      <SelectorBorder
                        key={`item_array_${main_array_index}_${item.id}`}
                        isMobile={isMobile}
                        onClick={() => {
                          this.setState({
                            trips_ejecuted: this.state.trips_ejecuted === null || this.state.trips_ejecuted !== item.id ? item.id : null
                          })
                        }}
                      >
                        <SelectorImgContainer 
                          key={`item_container_${main_array_index}_${item.id}`}
                          alt="" 
                          src={ this.getStep4OptionImage(item.id) }/>
                          
                        <SelectorTitle
                          key={`item_title_${main_array_index}_${item.id}`}
                        >{item.description}</SelectorTitle>
                      </SelectorBorder>
                    )
                  })
                }
              </SelectorContainer>
            );
          })
        }
      </>
    );
  }

  buildStep4 = (isMobile) => {
    return(
      <>
        <ImgContainer>
          <ImgQuestion
            alt="" 
            isMobile={isMobile}
            src={ this.state.trips_ejecuted !== null ? "/images/new_client_co/iconos/Pag 4 Pregunta act.svg" : "/images/new_client_co/iconos/Pag 4 Pregunta ina.svg"}/>
        </ImgContainer>
        <FieldTitle
          isMobile={isMobile}
        >
          {'Hablemos de operaciones '}
          <span class='underline'>{'mensuales'}</span>
          </FieldTitle>
        <FieldTitle
          isMobile={isMobile}
        >
          {'¿Cuántos camiones completos recibes en distribución '}
          <span class='underline'>{'primaria outbound'}</span>
          {'? '}
          <br/>
          <span class="medium">{'de tus fábricas a cedis:'}</span>
        </FieldTitle>

        {
          this.getStep4Options(isMobile)
        }
      </>
    );
  }

  getStep5OptionImage = (id) => {
    switch(id) {
      case 1: return this.state.trips_ejecuted_t2 !== null && this.state.trips_ejecuted_t2 === id ? "/images/new_client_co/iconos/Pag 3-6 Respuesta 1 act.svg" : "/images/new_client_co/iconos/Pag 3-6 Respuesta 1 ina.svg"
      case 2: return this.state.trips_ejecuted_t2 !== null && this.state.trips_ejecuted_t2 === id ? "/images/new_client_co/iconos/Pag 3-6 Respuesta 2 act.svg" : "/images/new_client_co/iconos/Pag 3-6 Respuesta 2 ina.svg"
      case 3: return this.state.trips_ejecuted_t2 !== null && this.state.trips_ejecuted_t2 === id ? "/images/new_client_co/iconos/Pag 3-6 Respuesta 3 act.svg" : "/images/new_client_co/iconos/Pag 3-6 Respuesta 3 ina.svg"
      case 4: return this.state.trips_ejecuted_t2 !== null && this.state.trips_ejecuted_t2 === id ? "/images/new_client_co/iconos/Pag 3-6 Respuesta 4 act.svg" : "/images/new_client_co/iconos/Pag 3-6 Respuesta 4 ina.svg"
    }

    return "/images/new_client_co/iconos/Pag 3-6 Respuesta 1 act.svg";
  }

  getStep5Options = (isMobile) => {

    let _ = require("lodash");
    let arr = _.chunk((this.props.secundary_distribution_t2 || []), 4);
    
    return(
      <>
        {
          arr.map( (main_array, main_array_index) => {
            return(
              <SelectorContainer
                key={`main_array_${main_array_index}`}
              >
                {
                  main_array.map( item => {
                    return(
                      <SelectorBorder
                        key={`item_array_${main_array_index}_${item.id}`}
                        isMobile={isMobile}
                        onClick={() => {
                          this.setState({
                            trips_ejecuted_t2: this.state.trips_ejecuted_t2 === null || this.state.trips_ejecuted_t2 !== item.id ? item.id : null
                          })
                        }}
                      >
                        <SelectorImgContainer 
                          key={`item_container_${main_array_index}_${item.id}`}
                          alt="" 
                          src={ this.getStep5OptionImage(item.id) }/>
                          
                        <SelectorTitle
                          key={`item_title_${main_array_index}_${item.id}`}
                        >{item.description}</SelectorTitle>
                      </SelectorBorder>
                    )
                  })
                }
              </SelectorContainer>
            );
          })
        }
      </>
    );
  }

  buildStep5 = (isMobile) => {
    return(
      <>
        <ImgContainer>
          <ImgQuestion
            alt="" 
            isMobile={isMobile}
            src={ this.state.trips_ejecuted_t2 !== null ? "/images/new_client_co/iconos/Pag 5 Pregunta act.svg" : "/images/new_client_co/iconos/Pag 5 Pregunta ina.svg"}/>
        </ImgContainer>
        <FieldTitle
          isMobile={isMobile}
        >
          {'Hablemos de operaciones '}
          <span class='underline'>{'mensuales'}</span>
        </FieldTitle>
        <FieldTitle
          isMobile={isMobile}
        >
          {'¿Cuántas entregas FTL o LTL haces en tú distribución '}
          <span class='underline'>{'secundaria'}</span>
          {'? '}
          <br/>
          <span class="medium">{'de tus fábricas/cedis a puntos de venta:'}</span>
        </FieldTitle>

        { this.getStep5Options(isMobile) }
        
      </>
    );
  }

  getStep6OptionImage = (id) => {
    switch(id) {
      case 1: return this.state.trips_ejecuted_last_mile !== null && this.state.trips_ejecuted_last_mile === id ? "/images/new_client_co/iconos/Pag 3-6 Respuesta 1 act.svg" : "/images/new_client_co/iconos/Pag 3-6 Respuesta 1 ina.svg"
      case 2: return this.state.trips_ejecuted_last_mile !== null && this.state.trips_ejecuted_last_mile === id ? "/images/new_client_co/iconos/Pag 3-6 Respuesta 2 act.svg" : "/images/new_client_co/iconos/Pag 3-6 Respuesta 2 ina.svg"
      case 3: return this.state.trips_ejecuted_last_mile !== null && this.state.trips_ejecuted_last_mile === id ? "/images/new_client_co/iconos/Pag 3-6 Respuesta 3 act.svg" : "/images/new_client_co/iconos/Pag 3-6 Respuesta 3 ina.svg"
      case 4: return this.state.trips_ejecuted_last_mile !== null && this.state.trips_ejecuted_last_mile === id ? "/images/new_client_co/iconos/Pag 3-6 Respuesta 4 act.svg" : "/images/new_client_co/iconos/Pag 3-6 Respuesta 4 ina.svg"
    }

    return "/images/new_client_co/iconos/Pag 3-6 Respuesta 1 act.svg";
  }

  getStep6Options = (isMobile) => {

    let _ = require("lodash");
    let arr = _.chunk((this.props.last_mile || []), 4);
    
    return(
      <>
        {
          arr.map( (main_array, main_array_index) => {
            return(
              <SelectorContainer
                key={`main_array_${main_array_index}`}
              >
                {
                  main_array.map( item => {
                    return(
                      <SelectorBorder
                        key={`item_array_${main_array_index}_${item.id}`}
                        isMobile={isMobile}
                        onClick={() => {
                          this.setState({
                            trips_ejecuted_last_mile: this.state.trips_ejecuted_last_mile === null || this.state.trips_ejecuted_last_mile !== item.id ? item.id : null
                          })
                        }}
                      >
                        <SelectorImgContainer 
                          key={`item_container_${main_array_index}_${item.id}`}
                          alt="" 
                          src={ this.getStep6OptionImage(item.id) }/>
                          
                        <SelectorTitle
                          key={`item_title_${main_array_index}_${item.id}`}
                        >{item.description}</SelectorTitle>
                      </SelectorBorder>
                    )
                  })
                }
              </SelectorContainer>
            );
          })
        }
      </>
    );
  }

  buildStep6 = (isMobile) => {
    return(
      <>
        <ImgContainer>
          <ImgQuestion
            alt="" 
            isMobile={isMobile}
            src={ this.state.trips_ejecuted_last_mile !== null ? "/images/new_client_co/iconos/Pag 6 Pregunta act.svg" : "/images/new_client_co/iconos/Pag 6 Pregunta ina.svg"}/>
        </ImgContainer>
        <FieldTitle
          isMobile={isMobile}
        >
          {'Hablemos de operaciones '}
          <span class='underline'>{'mensuales'}</span>
        </FieldTitle>
        <FieldTitle
          isMobile={isMobile}
        >
          {'¿Cuántas entregas haces en distribución de '}
          <span class='underline'>{'última milla'}</span>
          {'? '}
          <br/>
          <span class="medium">{'de tus cedis/puntos de venta a tus clientes/consumidor final:'}</span>
        </FieldTitle>

        { this.getStep6Options(isMobile) }
        
      </>
    );
  }

  getStep7ptions = () => {
    let arr = this.props.faculties_in_organization || [];

    return(
      <CheckboxContainerLevel1>
        <CheckboxContainerLevel2>
          {
            arr.map( item => {
              return(
                <React.Fragment key={`group_step_7_${item.id}`}>
                  <Checkbox
                    key={`checkbox_step_7_${item.id}`}
                    onChange={this.handleStepCheckbox}
                    item={{
                      id: item.id,
                      description: item.description,
                      active: this.state.faculties_in_organization.includes(`${item.id}`),
                    }}
                    name={'faculties_in_organization'}
                    className='clear-color'/>

                  <Separator
                    key={`checkbox_separator_step_7_${item.id}`}
                  />
                </React.Fragment>
              );
            })
          }
        </CheckboxContainerLevel2>
      </CheckboxContainerLevel1>
    );
  }

  buildStep7 = (isMobile) => {
    return(
      <>
        <ImgContainer>
          <ImgQuestionSmall
            alt="" 
            isMobile={isMobile}
            src={ this.state.role === '' || this.state.faculties_in_organization.length === 0 ? "/images/new_client_co/iconos/Pag 7 Pregunta ina.svg" : "/images/new_client_co/iconos/Pag 7 Pregunta act.svg"}/>
        </ImgContainer>
        <FieldTitle
          isMobile={isMobile}
        >{'¿Cuál es tu rol en tu empresa?:'}</FieldTitle>
        <TextArea
          name="role"
          isMobile={isMobile}
          value={this.state.role}
          onChange={this.handleChange}
          placeholder={"Indique aquí (máximo 200 caracteres)..."}/>

        <FieldTitle
          isMobile={isMobile}
        >{'Elige las facultades de tu rol:'}</FieldTitle>

        {
          this.getStep7ptions()
        }
      </>
    );
  }

  getCountersStep8 = () => {
    return {
      productivity: this.state.productivity_selected.length > 0,
      digital_transformation: this.state.digital_transformation_selected.length > 0,
      security: this.state.security_selected.length > 0,
      distribution_and_logistics: this.state.distribution_and_logistics_selected.length > 0,
      nps: this.state.nps_selected.length > 0,
      total: (this.state.productivity_selected.length + this.state.digital_transformation_selected.length + this.state.security_selected.length + this.state.distribution_and_logistics_selected.length + this.state.nps_selected.length) > 4
    };
  }

  handleStepCheckbox = (e) => {
    const { name, value, checked } = e.target;

    if (name === 'productivity') {
      if (checked) {
        this.state.productivity_selected.push(value);
      } else {
        this.state.productivity_selected = this.state.productivity_selected.filter( item => item !== value);
      }
    }

    if (name === 'digital_transformation') {
      if (checked) {
        this.state.digital_transformation_selected.push(value);
      } else {
        this.state.digital_transformation_selected = this.state.digital_transformation_selected.filter( item => item !== value);
      }
    }

    if (name === 'security') {
      if (checked) {
        this.state.security_selected.push(value);
      } else {
        this.state.security_selected = this.state.security_selected.filter( item => item !== value);
      }
    }

    if (name === 'distribution_and_logistics') {
      if (checked) {
        this.state.distribution_and_logistics_selected.push(value);
      } else {
        this.state.distribution_and_logistics_selected = this.state.distribution_and_logistics_selected.filter( item => item !== value);
      }
    }

    if (name === 'nps') {
      if (checked) {
        this.state.nps_selected.push(value);
      } else {
        this.state.nps_selected = this.state.nps_selected.filter( item => item !== value);
      }
    }

    if (name === 'faculties_in_organization') {
      if (checked) {
        this.state.faculties_in_organization.push(value);
      } else {
        this.state.faculties_in_organization = this.state.faculties_in_organization.filter( item => item !== value);
      }
    }
    
    this.setState({});
  }

  getStep8_1Options = () => {
    let arr = (this.props.challenges_concerns || []).filter( item => item.subcategory_id === 1);

    return(
      <>
        {
          arr.map( item => {
            return(
              <React.Fragment key={`checkbox_container_step_8_${item.id}`}>
                <Checkbox
                  key={`checkbox_step_8_${item.id}`}
                  onChange={this.handleStepCheckbox}
                  item={{
                    id: item.id,
                    description: item.description,
                    active: this.state.productivity_selected.includes(`${item.id}`),
                  }}
                  name={'productivity'}
                  className='clear-color'/>

                <Separator
                  key={`checkbox_separator_step_8_${item.id}`}
                />
              </React.Fragment>
            );
          })
        }
      </>
    );
  }

  buildStep8_1 = (isMobile) => {
    let counter = this.getCountersStep8();

    return(
      <>
        <ImgContainer>
          <ImgQuestionSmall
            alt="" 
            isMobile={isMobile}
            src={ counter.total ? "/images/new_client_co/iconos/Pag 8 y 9 Pregunta Principal act.svg" : "/images/new_client_co/iconos/Pag 8 y 9 Pregunta Principal ina.svg"}/>
        </ImgContainer>
        <FieldTitle
          isMobile={isMobile}
        >{'Platícanos los retos y frustraciones de tu negocio. Lee las 25 opciones y elige solo 5:'}</FieldTitle>
        <FieldSubtitle
          isMobile={isMobile}
        >
          <ImgSubtitle
            isMobile={isMobile}
            src={counter.productivity ? "/images/new_client_co/iconos/Pag 8 Pregunta A act.svg" : "/images/new_client_co/iconos/Pag 8 Pregunta A ina.svg"}
          />
          {'Productividad & Finanzas'}
        </FieldSubtitle>

        { this.getStep8_1Options() }

      </>
    );
  }

  getStep8_2Options = () => {
    let arr = (this.props.challenges_concerns || []).filter( item => item.subcategory_id === 2);

    return(
      <>
        {
          arr.map( item => {
            return(
              <React.Fragment key={`checkbox_container_step_8_${item.id}`}>
                <Checkbox
                  key={`checkbox_step_8_${item.id}`}
                  onChange={this.handleStepCheckbox}
                  item={{
                    id: item.id,
                    description: item.description,
                    active: this.state.digital_transformation_selected.includes(`${item.id}`),
                  }}
                  name={'digital_transformation'}
                  className='clear-color'/>

                <Separator
                  key={`checkbox_separator_step_8_${item.id}`}
                />
              </React.Fragment>
            );
          })
        }
      </>
    );
  }

  buildStep8_2 = (isMobile) => {
    let counter = this.getCountersStep8();

    return(
      <>
        <FieldSubtitle
          isMobile={isMobile}
        >
          <ImgSubtitle
            isMobile={isMobile}
            src={counter.digital_transformation ? "/images/new_client_co/iconos/Pag 8 Pregunta B act.svg" : "/images/new_client_co/iconos/Pag 8 Pregunta B ina.svg"}
          />
          {'Proyectos & Transformación Digital'}
        </FieldSubtitle>
        { this.getStep8_2Options() }
        
      </>
    );
  }

  getStep8_3Options = () => {
    let arr = (this.props.challenges_concerns || []).filter( item => item.subcategory_id === 3);

    return(
      <>
        {
          arr.map( item => {
            return(
              <React.Fragment key={`checkbox_container_step_8_${item.id}`}>
                <Checkbox
                  key={`checkbox_step_8_${item.id}`}
                  onChange={this.handleStepCheckbox}
                  item={{
                    id: item.id,
                    description: item.description,
                    active: this.state.security_selected.includes(`${item.id}`),
                  }}
                  name={'security'}
                  className='clear-color'/>

                <Separator
                  key={`checkbox_separator_step_8_${item.id}`}
                />
              </React.Fragment>
            );
          })
        }
      </>
    );
  }

  buildStep8_3 = (isMobile) => {
    let counter = this.getCountersStep8();

    return(
      <>
        <FieldSubtitle
          isMobile={isMobile}
        >
          <ImgSubtitle
            isMobile={isMobile}
            src={counter.security ? "/images/new_client_co/iconos/Pag 8 Pregunta C act.svg" : "/images/new_client_co/iconos/Pag 8 Pregunta C ina.svg"}
          />
          {'Seguridad y Regulación Normativa'}
        </FieldSubtitle>

        { this.getStep8_3Options() }
      </>
    );
  }

  getStep8_4Options = () => {
    let arr = (this.props.challenges_concerns || []).filter( item => item.subcategory_id === 4);

    return(
      <>
        {
          arr.map( item => {
            return(
              <React.Fragment key={`checkbox_container_step_8_${item.id}`}>
                <Checkbox
                  key={`checkbox_step_8_${item.id}`}
                  onChange={this.handleStepCheckbox}
                  item={{
                    id: item.id,
                    description: item.description,
                    active: this.state.distribution_and_logistics_selected.includes(`${item.id}`),
                  }}
                  name={'distribution_and_logistics'}
                  className='clear-color'/>

                <Separator
                  key={`checkbox_separator_step_8_${item.id}`}
                />
              </React.Fragment>
            );
          })
        }
      </>
    );
  }

  buildStep8_4 = (isMobile) => {
    let counter = this.getCountersStep8();

    return(
      <>
        <FieldSubtitle
          isMobile={isMobile}
        >
          <ImgSubtitle
            isMobile={isMobile}
            src={counter.distribution_and_logistics ? "/images/new_client_co/iconos/Pag 8 Pregunta D act.svg" : "/images/new_client_co/iconos/Pag 8 Pregunta D ina.svg"}
          />
          {'Operación: Distribución y Logística'}
        </FieldSubtitle>

        { this.getStep8_4Options() }
      </>
    );
  }

  getStep8_5Options = () => {
    let arr = (this.props.challenges_concerns || []).filter( item => item.subcategory_id === 5);

    return(
      <>
        {
          arr.map( item => {
            return(
              <React.Fragment key={`checkbox_container_step_8_${item.id}`}>
                <Checkbox
                  key={`checkbox_step_8_${item.id}`}
                  onChange={this.handleStepCheckbox}
                  item={{
                    id: item.id,
                    description: item.description,
                    active: this.state.nps_selected.includes(`${item.id}`),
                  }}
                  name={'nps'}
                  className='clear-color'/>

                <Separator
                  key={`checkbox_separator_step_8_${item.id}`}
                />
              </React.Fragment>
            );
          })
        }
      </>
    );
  }

  buildStep8_5 = (isMobile) => {
    let counter = this.getCountersStep8();

    return(
      <>
        <FieldSubtitle
          isMobile={isMobile}
        >
          <ImgSubtitle
            isMobile={isMobile}
            src={counter.nps ? "/images/new_client_co/iconos/Pag 8 Pregunta E act.svg" : "/images/new_client_co/iconos/Pag 8 Pregunta E ina.svg"}
          />
          {'Valor de Marca y NPS (Net Promoter Score)'}
        </FieldSubtitle>

        { this.getStep8_5Options() }
      </>
    );
  }

  buildStep8_6 = (isMobile) => {
    return(
      <>
        <FieldSubtitle
          isMobile={isMobile}
        >
          {'Otro'}
        </FieldSubtitle>

        <TextArea
          isMobile={isMobile}
          name="other_problems"
          value={this.state.other_problems}
          onChange={this.handleChange}
          placeholder={"Indique aquí (máximo 400 caracteres)..."}/>
      </>
    );
  }

  buildStep9 = (backend, isMobile) => {
    return(
      <>
        <ImgContainer>
          <ImgQuestion
            alt="" 
            isMobile={isMobile}
            src={"/images/new_client_co/iconos/Pag 8 y 9 Pregunta Principal act.svg"}/>
        </ImgContainer>
        <FieldTitle
          isMobile={isMobile}
        >{'Acomoda en orden de prioridad tus respuestas:'}</FieldTitle>

        <DndProvider backend={backend}>
          {
            this.state.order_items.map( (item, index) => {
              return(
                <Card
                  id={item.id}
                  key={item.id}
                  component={ 
                    <div
                      style={{
                        padding: 10,
                        width: '100%',
                        backgroundColor: '#2f3b52',
                        color: '#9ba1ac'
                      }}
                    >
                      {item.description}
                    </div>
                  }
                  index={index} 
                  moveCard={this.moveCard}
                  dropItems={() => {}}
                  isMobile={isMobile}
                />
              );
            })
          }
        </DndProvider>
      </>
    );
  }

  buildStep10 = (isMobile) => {
    return(
      <>
        <ImgContainer>
          <ImgQuestionSmall
            alt="" 
            isMobile={isMobile}
            src={ this.state.supply_chain_performance !== 0 ? "/images/new_client_co/iconos/Pag 10 Pregunta act.svg" : "/images/new_client_co/iconos/Pag 10 Pregunta ina.svg"}/>
        </ImgContainer>
        <FieldTitle
          isMobile={isMobile}
        >{'¿Cómo calificarías actualmente el desempeño de la cadena de abastecimiento de tu empresa? del 0 al 10:'}</FieldTitle>

        <RadioContainer>
          <RadioItem>
            {'1'}
            <input
              type="radio"
              name="supply_chain_performance"
              value={1}
              checked={`${this.state.supply_chain_performance}` === '1'}
              onChange={this.handleChange}
            />
          </RadioItem>

          <RadioItem>
            {'2'}
            <input
              type="radio"
              name="supply_chain_performance"
              value={2}
              checked={`${this.state.supply_chain_performance}` === '2'}
              onChange={this.handleChange}
            />
          </RadioItem>

          <RadioItem>
            {'3'}
            <input
              type="radio"
              name="supply_chain_performance"
              value={3}
              checked={`${this.state.supply_chain_performance}` === '3'}
              onChange={this.handleChange}
            />
          </RadioItem>

          <RadioItem>
            {'4'}
            <input
              type="radio"
              name="supply_chain_performance"
              value={4}
              checked={`${this.state.supply_chain_performance}` === '4'}
              onChange={this.handleChange}
            />
          </RadioItem>

          <RadioItem>
            {'5'}
            <input
              type="radio"
              name="supply_chain_performance"
              value={5}
              checked={`${this.state.supply_chain_performance}` === '5'}
              onChange={this.handleChange}
            />
          </RadioItem>

          <RadioItem>
            {'6'}
            <input
              type="radio"
              name="supply_chain_performance"
              value={6}
              checked={`${this.state.supply_chain_performance}` === '6'}
              onChange={this.handleChange}
            />
          </RadioItem>

          <RadioItem>
            {'7'}
            <input
              type="radio"
              name="supply_chain_performance"
              value={7}
              checked={`${this.state.supply_chain_performance}` === '7'}
              onChange={this.handleChange}
            />
          </RadioItem>

          <RadioItem>
            {'8'}
            <input
              type="radio"
              name="supply_chain_performance"
              value={8}
              checked={`${this.state.supply_chain_performance}` === '8'}
              onChange={this.handleChange}
            />
          </RadioItem>

          <RadioItem>
            {'9'}
            <input
              type="radio"
              name="supply_chain_performance"
              value={9}
              checked={`${this.state.supply_chain_performance}` === '9'}
              onChange={this.handleChange}
            />
          </RadioItem>

          <RadioItem>
            {'10'}
            <input
              type="radio"
              name="supply_chain_performance"
              value={10}
              checked={`${this.state.supply_chain_performance}` === '10'}
              onChange={this.handleChange}
            />
          </RadioItem>

        </RadioContainer>

      </>
    );
  }

  getStep11OptionImage = (id) => {
    switch(id) {
      case 1: return this.state.digital_transformation_item !== null && this.state.digital_transformation_item === id ? "/images/new_client_co/iconos/Pag 11 Respuesta 1 act.svg" : "/images/new_client_co/iconos/Pag 11 Respuesta 1 ina.svg"
      case 2: return this.state.digital_transformation_item !== null && this.state.digital_transformation_item === id ? "/images/new_client_co/iconos/Pag 11 Respuesta 2 act.svg" : "/images/new_client_co/iconos/Pag 11 Respuesta 2 ina.svg"
      case 3: return this.state.digital_transformation_item !== null && this.state.digital_transformation_item === id ? "/images/new_client_co/iconos/Pag 11 Respuesta 3 act.svg" : "/images/new_client_co/iconos/Pag 11 Respuesta 3 ina.svg"
      case 4: return this.state.digital_transformation_item !== null && this.state.digital_transformation_item === id ? "/images/new_client_co/iconos/Pag 11 Respuesta 4 act.svg" : "/images/new_client_co/iconos/Pag 11 Respuesta 4 ina.svg"
      case 5: return this.state.digital_transformation_item !== null && this.state.digital_transformation_item === id ? "/images/new_client_co/iconos/Pag 11 Respuesta 5 act.svg" : "/images/new_client_co/iconos/Pag 11 Respuesta 5 ina.svg"
    }

    return "/images/new_client_co/iconos/Pag 11 Respuesta 5 act.svg";
  }

  getStep11Options = (isMobile) => {

    let _ = require("lodash");
    let arr = _.chunk((this.props.digital_transformation || []), 5);

    if (isMobile) {
      return(
        <>
          {
            arr.map( (main_array, main_array_index) => {
              return(
                <SelectorContainerHorizontal
                  key={`step_11_main_array_${main_array_index}`}
                >
                  {
                    main_array.map( item => {
                      return(
                        <SelectorBorderHorizontalSmall
                          key={`step_11_item_array_${main_array_index}_${item.id}`}
                          onClick={() => {
                            this.setState({
                              digital_transformation_item: this.state.digital_transformation_item === null || this.state.digital_transformation_item !== item.id ? item.id : null
                            })
                          }}
                        >
                          <SelectorImgContainerSmall 
                            key={`item_container_${main_array_index}_${item.id}`}
                            alt="" 
                            src={ this.getStep11OptionImage(item.id) }/>
                            
                          <SelectorTitleHorizontal
                            key={`item_title_${main_array_index}_${item.id}`}
                          >{item.description}</SelectorTitleHorizontal>
                        </SelectorBorderHorizontalSmall>
                      );
                    })
                  }
                </SelectorContainerHorizontal>
              );
            })
          }
        </>
      );
    }
    
    return(
      <>
        {
          arr.map( (main_array, main_array_index) => {
            return(
              <SelectorContainer
                key={`step_11_main_array_${main_array_index}`}
              >
                {
                  main_array.map( item => {
                    return(
                      <SelectorBorderSmall
                        key={`step_11_item_array_${main_array_index}_${item.id}`}
                        isMobile={isMobile}
                        onClick={() => {
                          this.setState({
                            digital_transformation_item: this.state.digital_transformation_item === null || this.state.digital_transformation_item !== item.id ? item.id : null
                          })
                        }}
                      >
                        <SelectorImgContainer 
                          key={`item_container_${main_array_index}_${item.id}`}
                          alt="" 
                          src={ this.getStep11OptionImage(item.id) }/>
                          
                        <SelectorTitle
                          key={`item_title_${main_array_index}_${item.id}`}
                        >{item.description}</SelectorTitle>
                      </SelectorBorderSmall>
                    )
                  })
                }
              </SelectorContainer>
            );
          })
        }
      </>
    );
  }

  buildStep11 = (isMobile) => {
    return(
      <>
        <FieldTitle
          isMobile={isMobile}>
          {'¿En qué nivel de adopción de transformación digital consideras que se encuentra tu empresa?:'}
        </FieldTitle>

        { this.getStep11Options(isMobile) }

      </>
    );
  }

  getStep12ptions_1 = () => {
    let arr = this.props.time_in_process || [];

    return(
      <CheckboxContainerLevel1>
        <CheckboxContainerLevel2>
          {
            arr.map( item => {
              return(
                <React.Fragment key={`checkbox_container_step_12_1_${item.id}`}>
                  <Checkbox
                    key={`checkbox_step_12_1_${item.id}`}
                    onChange={this.handleChange}
                    item={{
                      id: item.id,
                      description: item.description,
                      active: this.state.time_in_process == `${item.id}`
                    }}
                    name={'time_in_process'}
                    className='clear-color'/>

                  <Separator
                    key={`checkbox_separator_step_12_1_${item.id}`}
                  />
                </React.Fragment>
              );
            })
          }
        </CheckboxContainerLevel2>
      </CheckboxContainerLevel1>
    );
  }

  getStep12ptions_2 = () => {
    let arr = this.props.budget_invested || [];

    return(
      <CheckboxContainerLevel1>
        <CheckboxContainerLevel2>
          {
            arr.map( item => {
              return(
                <React.Fragment key={`checkbox_container_step_12_2_${item.id}`}>
                  <Checkbox
                    key={`checkbox_step_12_2_${item.id}`}
                    onChange={this.handleChange}
                    item={{
                      id: item.id,
                      description: item.description,
                      active: this.state.budget_invested_in_your_project == `${item.id}`
                    }}
                    name={'budget_invested_in_your_project'}
                    className='clear-color'/>

                  <Separator
                    key={`checkbox_separator_step_12_2_${item.id}`}
                  />
                </React.Fragment>
              );
            })
          }
        </CheckboxContainerLevel2>
      </CheckboxContainerLevel1>
    );
  }

  buildStep12 = (isMobile) => {
    return(
      <>
        <FieldTitle
          isMobile={isMobile}
        >{'¿Están actualmente en algún proceso de adopción de tecnologías en tu cadena de abastecimiento?:'}</FieldTitle>

        <SelectorContainer>
          <SelectorBorder
            isMobile={isMobile}
            onClick={() => {
              this.setState({
                digital_transformation_process_or_adoption: true
              })
            }}
          >
            <SelectorImgContainer 
              alt="" 
              src={ this.state.digital_transformation_process_or_adoption ? "/images/new_client_co/iconos/Pag 12 Respuesta Si act.svg" : "/images/new_client_co/iconos/Pag 12 Respuesta Si ina.svg" }/>
              <SelectorTitleMedium>{'Si'}</SelectorTitleMedium>
          </SelectorBorder>
          
          <SelectorBorder
            isMobile={isMobile}
            onClick={() => {
              this.setState({
                digital_transformation_process_or_adoption: false
              })
            }}
          >
            <SelectorImgContainer 
              alt="" 
              src={ this.state.digital_transformation_process_or_adoption === false ? "/images/new_client_co/iconos/Pag 12 Respuesta No act.svg" : "/images/new_client_co/iconos/Pag 12 Respuesta No ina.svg" }/>
              <SelectorTitleMedium>{'No'}</SelectorTitleMedium>
          </SelectorBorder>
        </SelectorContainer>

        {
          this.state.digital_transformation_process_or_adoption === true &&
          <>
            <FieldTitle
              isMobile={isMobile}
            >{'¿Cuánto tiempo llevan en ese proceso?:'}</FieldTitle>

            { this.getStep12ptions_1() }

            <FieldTitle
              isMobile={isMobile}
            >{'¿Cuánto llevan invertido?:'}</FieldTitle>

            { this.getStep12ptions_2() }
          </>
        }

        {
          this.state.digital_transformation_process_or_adoption === false &&
          <>
            <FieldTitle
              isMobile={isMobile}
            >{'¿Qué les falta para iniciar un proyecto?:'}</FieldTitle>

            <TextArea
              isMobile={isMobile}
              name="things_required_to_start_a_project"
              value={this.state.things_required_to_start_a_project}
              onChange={this.handleChange}
              placeholder={"Indique aquí (máximo 400 caracteres)..."}/>
          </>
        }
      </>
    )
  }

  getStep13ptions = () => {
    let arr = this.props.budget_invested || [];

    return(
      <CheckboxContainerLevel1>
        <CheckboxContainerLevel2>
          {
            arr.map( item => {
              return(
                <React.Fragment key={`checkbox_container_step_13_${item.id}`}>
                  <Checkbox
                    key={`checkbox_step_13_${item.id}`}
                    onChange={this.handleChange}
                    item={{
                      id: item.id,
                      description: item.description,
                      active: this.state.budget_required_project == `${item.id}`
                    }}
                    name={'budget_required_project'}
                    className='clear-color'/>

                  <Separator
                    key={`checkbox_separator_step_13_${item.id}`}
                  />
                </React.Fragment>
              );
            })
          }
        </CheckboxContainerLevel2>
      </CheckboxContainerLevel1>
    );
  }

  buildStep13 = (isMobile) => {
    return(
      <>
        <ImgContainer>
          <ImgQuestionMedium
            alt="" 
            isMobile={isMobile}
            src={ this.state.wish_in_supply_chain !== '' && this.state.budget_required_project !== null ? "/images/new_client_co/iconos/Pag 13 Pregunta act.svg" : "/images/new_client_co/iconos/Pag 13 Pregunta ina.svg"}/>
        </ImgContainer>
        <FieldTitle
          isMobile={isMobile}
        >{'Pide un deseo. Imagínate que tienes el poder de transformar cualquier cosa en tu cadena de abastecimiento en 90 días, ¿Cuál sería?:'}</FieldTitle>

        <TextArea
          isMobile={isMobile}
          name="wish_in_supply_chain"
          value={this.state.wish_in_supply_chain}
          onChange={this.handleChange}
          placeholder={"Indique aquí (máximo 400 caracteres)..."}/>

        <FieldTitle
          isMobile={isMobile}
        >{'¿Cuánto crees que costaría?:'}</FieldTitle>
        { this.getStep13ptions() }
      </>
    );
  }

  buildStep14 = (isMobile) => {
    return(
      <>
        <ImgContainer>
          <ImgQuestionSmall
            alt="" 
            isMobile={isMobile}
            src={ this.state.benefits_to_your_company_in_orbi !== '' ? "/images/new_client_co/iconos/Pag 14 Pregunta act.svg" : "/images/new_client_co/iconos/Pag 14 Pregunta ina.svg"}/>
        </ImgContainer>
        <FieldTitle
          isMobile={isMobile}
        >{'¿De qué manera crees que Orbinetwork le beneficiaría a tu empresa, a tus clientes y a tus proveedores?:'}</FieldTitle>

        <TextArea
          isMobile={isMobile}
          name="benefits_to_your_company_in_orbi"
          value={this.state.benefits_to_your_company_in_orbi}
          onChange={this.handleChange}
          placeholder={"Indique aquí (máximo 400 caracteres)..."}/>
      </>
    );
  }

  buildStep15 = (isMobile) => {
    return(
      <>
        <ImgContainer>
          <ImgQuestionSmall
            alt="" 
            isMobile={isMobile}
            src={ this.state.who_else_involved_in_your_company !== '' ? "/images/new_client_co/iconos/Pag 15 Pregunta act.svg" : "/images/new_client_co/iconos/Pag 15 Pregunta ina.svg"}/>
        </ImgContainer>
        <FieldTitle
          isMobile={isMobile}
        >{'Además de ti ¿Quién más toma decisiones estratégicas en tu empresa?:'}</FieldTitle>

        <TextArea
          isMobile={isMobile}
          name="who_else_involved_in_your_company"
          value={this.state.who_else_involved_in_your_company}
          onChange={this.handleChange}
          placeholder={"Indique aquí (máximo 400 caracteres)..."}/>
      </>
    )
  }

  buildStep16 = (isMobile) => {
    return(
      <>
        <ImgContainer>
          <ImgQuestionSmall
            alt="" 
            isMobile={isMobile}
            src={ this.isInvalidStep16() ?  "/images/new_client_co/iconos/Pag 16 Pregunta ina.svg" : "/images/new_client_co/iconos/Pag 16 Pregunta act.svg"}/>
        </ImgContainer>
        <div>
          <TextInput
            type='text'
            label="Correo electrónico"
            name="email"
            value={this.state.email}
            className="item"
            onChange={this.handleChange}
          />
        </div>

        <div className="phone-number">
          <TextInput
            type='number'
            label="Celular"
            name="mobile_phone"
            value={this.state.mobile_phone}
            className="item"
            onChange={this.handleChange}
            handleCustomEnter={(e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault()}
          />
        </div>

        {
          this.state.mobile_phone !== '' &&
          <>
            <FieldTitle
              isMobile={isMobile}
            >{'Medio de contacto:'}</FieldTitle>
            {
              this.getStep16Options(isMobile)
            }
          </>
        }

        <FieldTitle
          isMobile={isMobile}
        >{'¿Algún agente comercial o referido te recomendó con nosotros?:'}</FieldTitle>

        <SelectorContainer>
          <SelectorBorder
            isMobile={isMobile}
            onClick={() => {
              this.setState({
                is_someone_attending: this.state.is_someone_attending === true ? null : true
              })
            }}
          >
            <SelectorImgContainer 
              alt="" 
              src={ this.state.is_someone_attending ? "/images/new_client_co/iconos/Pag 12 Respuesta Si act.svg" : "/images/new_client_co/iconos/Pag 12 Respuesta Si ina.svg" }/>
              <SelectorTitleMedium>{'Si'}</SelectorTitleMedium>
          </SelectorBorder>
          
          <SelectorBorder
            isMobile={isMobile}
            onClick={() => {
              this.setState({
                is_someone_attending: this.state.is_someone_attending === false ? null : false
              })
            }}
          >
            <SelectorImgContainer 
              alt="" 
              src={ this.state.is_someone_attending === false ? "/images/new_client_co/iconos/Pag 12 Respuesta No act.svg" : "/images/new_client_co/iconos/Pag 12 Respuesta No ina.svg" }/>
              <SelectorTitleMedium>{'No'}</SelectorTitleMedium>
          </SelectorBorder>
        </SelectorContainer>

        {
          this.state.is_someone_attending &&
          <>
            <div className="resume-row" style={{position: 'relative', zIndex: 99999, marginBottom: 15}}>
              <div style={{width: "100%"}}>
                <FieldTitle
                  isMobile={isMobile}
                >{'Ingresa el código de referencia:'}</FieldTitle>

                {
                  this.state.agent !== null &&
                  <AgentContainer
                    onClick={() => {
                      this.setState({
                        agent: null
                      })
                    }}
                  >
                    {`Agente ${this.state.agent.code} - ${this.state.agent.name}`}
                    <img
                      alt=""
                      src="/images/close.svg"
                      style={{ width: 15, marginLeft: 10}}
                    />
                  </AgentContainer>
                }

                <TextInput
                  label='Indique aquí ...'
                  type='text'
                  value={this.state.is_someone_attending_name}
                  name='is_someone_attending_name'
                  onChange={(e) => {
                    const { name, value } = e.target;

                    if (String(value).length > 2) {
                      this.props.cancelMultipleRequests();
                      this.props.getSalesAgent({
                        agent: value,
                        requiresAuth: false,
                        isMultipleCancels: true
                      }, {});
                    }

                    this.setState({[name]: value});

                  }}
                />

                <OptionsList
                  width={'100%'}
                  visible={this.props.agents.length > 0 && this.state.is_someone_attending_name.length > 2}
                  loading={this.props.isLoading_agent}
                  items={this.props.agents.map( e => `${e.code} - ${e.name}`)}
                  onClick={(value) => {
                    let dataToSave = null;

                    for (var i = 0; i < this.props.agents.length; i++) {
                      let agent = this.props.agents[i];

                      if (value === `${agent.code} - ${agent.name}`) {
                        dataToSave = agent;
                      }
                    }

                    this.setState({agent: dataToSave, is_someone_attending_name: ''});
                  }}
                />
              </div>
            </div>
          </>
        }
      </>
    )
  }

  buildStep17 = (isMobile) => {
    return(
      <>
        <ImgContainer>
          <ImgQuestionSmall
            alt="" 
            isMobile={isMobile}
            src={"/images/new_client_co/iconos/Pag 20 Pregunta act.svg"}/>
        </ImgContainer>
        <FieldTitle
          isMobile={isMobile}
        >
          {'¡Muchas gracias por tu valioso tiempo!'}
          <br/>
          {'y'}
          <br/>
          {'¡Felicidades!'}
        </FieldTitle>

        <FieldTitle
          isMobile={isMobile}
        >
          {
            !this.state.booked ?
            <span class='medium'>{'Has decidido dar el paso más importante para la transformación más rápida y simple de tu negocio entrando a la comunidad Orbinetwork. Uno de nuestros expertos te contactará lo antes posible para gestionar tu invitación. Si prefieres puedes:'}</span> :
            <span class='medium'>{'Has decidido dar el paso más importante para la transformación más rápida y simple de tu negocio entrando a la comunidad Orbinetwork.'}</span>
          }
        </FieldTitle>

        {
          this.state.booked &&
          <FieldTitle
            isMobile={isMobile}
            style={{marginTop: -25}}
          > 
            <span class='medium'>{'Nos vemos pronto en la videollamada agendada.'}</span>
          </FieldTitle>
        }

        {
          !this.state.booked &&
          <>
            <CheckboxContainerLevel1>
              <CheckboxContainerLevel2>
                <Button
                  settings={{
                    type: "primary outline-with-padding blue",
                    text: 'Agendar llamada aquí',
                    onClick: () => {
                      window.open("https://outlook.office365.com/owa/calendar/VideollamadaconOrbinetwork@orbinetwork.com/bookings/", "_blank")
                    }
                  }}
                />
              </CheckboxContainerLevel2>
            </CheckboxContainerLevel1>

            <FieldTitle
              isMobile={isMobile}
            >
              <span class='small'>{'o agendar después con la liga que enviamos a tu correo electrónico.'}</span>
            </FieldTitle>
          </>
        }

        <FieldTitle
          isMobile={isMobile}
        >
          {'¡Bienvenido!'}
        </FieldTitle>

        <CheckboxContainerLevel1>
          <CheckboxContainerLevel2>
            <Button
              settings={{
                type: "primary fit-all-width",
                text: 'Salir',
                onClick: () => {
                  this.props.history.push('/login');
                }
              }}
            />
          </CheckboxContainerLevel2>
        </CheckboxContainerLevel1>
      </>
    )
  }

  isInvalidStep16 = () => {
    let is_invalid = false;

    if (this.state.email === '' || !validateEmail(this.state.email)) {
      is_invalid = true;
    }

    if (this.state.mobile_phone === '' || this.state.mobile_phone.length !== 10) {
      is_invalid = true;
    }

    if (this.state.contact_type === null) {
      is_invalid = true;
    }

    if (this.state.is_someone_attending === null) {
      is_invalid = true;
    }

    if (this.state.is_someone_attending && this.state.agent === null) {
      is_invalid = true;
    }

    return is_invalid;
  }

  isContinueDisabled = () => {
    let isDisabled = true;

    if (this.state.currentStep === 1) {
      isDisabled = this.state.name === '' || this.state.company_name === '';
    } else if (this.state.currentStep === 2) {
      isDisabled = this.state.industry_type === null;
    } else if (this.state.currentStep === 3) {
      isDisabled = this.state.trips_received === null;
    } else if (this.state.currentStep === 4) {
      isDisabled = this.state.trips_ejecuted === null;
    } else if (this.state.currentStep === 5) {
      isDisabled = this.state.trips_ejecuted_t2 === null;
    } else if (this.state.currentStep === 6) {
      isDisabled = this.state.trips_ejecuted_last_mile === null;
    } else if (this.state.currentStep === 7) {
      isDisabled = this.state.role === '' || this.state.faculties_in_organization.length === 0;
    } else if (this.state.currentStep === 8) {
      let counter = this.getCountersStep8();
      isDisabled = !counter.total;
    } else if (this.state.currentStep === 9) {
      isDisabled = false;
    } else if (this.state.currentStep === 10) {
      isDisabled = this.state.supply_chain_performance === 0;
    } else if (this.state.currentStep === 11) {
      isDisabled = this.state.digital_transformation_item === null;
    } else if (this.state.currentStep === 12) {

      if (this.state.digital_transformation_process_or_adoption === null) {
        return true
      }

      if (this.state.digital_transformation_process_or_adoption) {
        isDisabled = this.state.time_in_process === null || this.state.budget_invested_in_your_project === null;
      } else {
        isDisabled = this.state.things_required_to_start_a_project === '';
      }
    } else if (this.state.currentStep === 13) {
      isDisabled = this.state.budget_required_project === null || this.state.wish_in_supply_chain === '';
    } else if (this.state.currentStep === 14) {
      isDisabled = this.state.benefits_to_your_company_in_orbi === '';
    } else if (this.state.currentStep === 15) {
      isDisabled = this.state.who_else_involved_in_your_company === '';
    } else if (this.state.currentStep === 16) {
      isDisabled = this.isInvalidStep16();
    }
    
    return isDisabled;
  }

  getDataToSave = () => {
    let data_to_save = {};

    if (this.state.currentStep === 1) {
      data_to_save = {
        name: this.state.name,
        company_name: this.state.company_name,
        step: 1
      }
    } else if (this.state.currentStep === 2) {
      data_to_save = {
        industry_type: this.state.industry_type,
        step: 2
      }
    } else if (this.state.currentStep === 3) {
      data_to_save = {
        trips_received: this.state.trips_received,
        step: 3
      }
    } else if (this.state.currentStep === 4) {
      data_to_save = {
        trips_ejecuted: this.state.trips_ejecuted,
        step: 4
      }
    } else if (this.state.currentStep === 5) {
      data_to_save = {
        trips_ejecuted_t2: this.state.trips_ejecuted_t2,
        step: 5
      }
    } else if (this.state.currentStep === 6) {
      data_to_save = {
        trips_ejecuted_last_mile: this.state.trips_ejecuted_last_mile,
        step: 6
      }
    } else if (this.state.currentStep === 7) {
      data_to_save = {
        role: this.state.role,
        faculties_in_organization: this.state.faculties_in_organization.join(','),
        step: 7
      }
    } else if (this.state.currentStep === 8) {
      data_to_save = {
        productivity: this.state.productivity_selected.join(','),
        digital_transformation: this.state.digital_transformation_selected.join(','),
        security: this.state.security_selected.join(','),
        distribution_and_logistics: this.state.distribution_and_logistics_selected.join(','),
        nps: this.state.nps_selected.join(','),
        other_problems: this.state.other_problems,
        step: 8
      }
    } else if (this.state.currentStep === 9) {
      data_to_save = {
        challenges_concerns_order: this.state.order_items.map( item => item.id ).join(','),
        step: 9
      }
    } else if (this.state.currentStep === 10) {
      data_to_save = {
        supply_chain_performance: parseInt(this.state.supply_chain_performance),
        step: 10
      }
    } else if (this.state.currentStep === 11) {
      data_to_save = {
        digital_transformation_item: this.state.digital_transformation_item,
        step: 11
      }
    } else if (this.state.currentStep === 12) {
      if (this.state.digital_transformation_process_or_adoption) {
        data_to_save = {
          digital_transformation_process_or_adoption: this.state.digital_transformation_process_or_adoption,
          time_in_process: parseInt(this.state.time_in_process),
          budget_invested_in_your_project: parseInt(this.state.budget_invested_in_your_project),
          step: 12
        }
      } else {
        data_to_save = {
          digital_transformation_process_or_adoption: this.state.digital_transformation_process_or_adoption,
          things_required_to_start_a_project: this.state.things_required_to_start_a_project,
          step: 12
        }
      }
    } else if (this.state.currentStep === 13) {
      data_to_save = {
        wish_in_supply_chain: this.state.wish_in_supply_chain,
        budget_required_project: parseInt(this.state.budget_required_project),
        step: 13
      }
    } else if (this.state.currentStep === 14) {
      data_to_save = {
        benefits_to_your_company_in_orbi: this.state.benefits_to_your_company_in_orbi,
        step: 14
      }
    } else if (this.state.currentStep === 15) {
      data_to_save = {
        who_else_involved_in_your_company: this.state.who_else_involved_in_your_company,
        step: 15
      }
    } else if (this.state.currentStep === 16) {
      if (this.state.is_someone_attending) {
        data_to_save = {
          email: this.state.email,
          mobile_phone: this.state.mobile_phone,
          contact_type: this.state.contact_type,
          is_someone_attending: true,
          step: 16
        }
      } else {
        data_to_save = {
          email: this.state.email,
          mobile_phone: this.state.mobile_phone,
          contact_type: this.state.contact_type,
          is_someone_attending: false,
          step: 16
        }
      }
    }

    return data_to_save;
  }

  render() {
    let isMobile = window.innerWidth < 900;

    return(
      <MainContent
        isSmallSize={isMobile}
        className="register-new-client-co"
        ref={scrollRef}
      >
        {
          (this.state.currentStep > 1 && this.state.currentStep < 17) &&
          <BackContainer
            isMobile={isMobile}
          >
            <BackButton
              isMobile={isMobile}
              onClick={() => {
                let previuos_step = (this.state.currentStep === 10 && isMobile) ? this.state.currentStep - 2 : this.state.currentStep - 1;

                this.setState({
                  currentStep: previuos_step
                });
              }}
            >
              { 
                isMobile ? ' ᐸ ' : 'ᐸ Regresar'
              }
            </BackButton>
          </BackContainer>
        }

        <img alt="" className="logo" src="/images/logo-white.svg" />

        <ProgressBarContainer>
          <ProgressBar progress_width={(this.state.currentStep * 100) / this.state.allSteps}/>
        </ProgressBarContainer>
        
        {
          this.handleSteps(isMobile)
        }

        <BottomBlankSpace/>

        <BottomContainer
          isSmallSize={isMobile}
        >
          {
            this.state.currentStep !== this.state.allSteps &&
            <Button
              settings={{
                disabled: this.isContinueDisabled() || this.props.isLoading,
                type: "primary fit-all-width",
                text: this.props.isLoading ? <Loader /> : 'Continuar',
                onClick: () => {
                  if (this.state.currentStep === 8) {

                    let total_array = this.state.productivity_selected.concat(
                      this.state.digital_transformation_selected,
                      this.state.security_selected,
                      this.state.distribution_and_logistics_selected,
                      this.state.nps_selected
                    );
                    
                    this.state.order_items = (this.props.challenges_concerns || []).filter( item => total_array.includes(`${item.id}`) );

                    if (this.state.other_problems !== '') {
                      this.state.order_items.push({
                        id: 0,
                        description: 'Otro'
                      });
                    }
                  }

                  let data = this.getDataToSave();
                  data.requiresAuth = false;

                  if (this.state.currentStep === 1 && this.state.client === null) {
                    this.props.createClient(data, {
                      onSuccess: (res) => {
                        this.setState({currentStep: this.state.currentStep + 1, client: res});
                      },
                      onError: (e) => {
                        this.setState({error: e.response?.data?.error || 'Ocurrió un error'})
                      }
                    });
                  } else {

                    data.client_id = this.state.client.id;

                    if (this.state.agent !== null) {
                      data.agent_id = this.state.agent.id;
                    }

                    if (this.state.booked) {
                      data.booked = true;
                    }

                    this.props.updateClient(data, {
                      onSuccess: (res) => {
                        scrollRef.current.scroll({top: 0});

                        let next_step = this.state.currentStep === 8 && isMobile ? this.state.currentStep + 2 : this.state.currentStep + 1;
                        this.setState({currentStep: next_step, client: res});
                      },
                      onError: (e) => {
                        this.setState({error: e.response?.data?.error || 'Ocurrió un error'})
                      }
                    });
                  }
                }
              }}
            />
          }
        </BottomContainer>

        <ConfirmDialog
          open={this.state.error !== ''}
          title={'¡Error!'}
          message={`${this.state.error}`}
          acceptText="Aceptar"
          acceptAction={() => this.setState({error: ''})} 
          closeAction={() => this.setState({error: ''})} 
        />
        
      </MainContent>
    );
  }
}

const mapStateToProps = (state) => {

  let isLoading = state?.api["COMPANIES.NEWCLIENTCO"]?.status?.isFetching || false;
  let contact_type = getEntityItems(state, "COMPANIES.NEWCLIENTCO.CATALOG.CONTACTTYPE");
  let industry_type_catalog = getEntityItems(state, "COMPANIES.NEWCLIENTCO.CATALOG.INDUSTRYTYPECATALOG");
  let primary_distribution_t1 = getEntityItems(state, "COMPANIES.NEWCLIENTCO.CATALOG.PRIMARYDISTRIBUTIONT1");
  let secundary_distribution_t2 = getEntityItems(state, "COMPANIES.NEWCLIENTCO.CATALOG.SECUNDARYDISTRIBUTIONT2");
  let last_mile = getEntityItems(state, "COMPANIES.NEWCLIENTCO.CATALOG.LASTMILE");
  let challenges_concerns = getEntityItems(state, "COMPANIES.NEWCLIENTCO.CATALOG.CHALLENGESCONCERNS");
  let digital_transformation = getEntityItems(state, "COMPANIES.NEWCLIENTCO.CATALOG.DIGITALTRANSFORMATION");
  let time_in_process = getEntityItems(state, "COMPANIES.NEWCLIENTCO.CATALOG.TIMEINPROCESS");
  let budget_invested = getEntityItems(state, "COMPANIES.NEWCLIENTCO.CATALOG.BUDGETINVESTED");
  let faculties_in_organization = getEntityItems(state, "COMPANIES.NEWCLIENTCO.CATALOG.FACULTIESINORGANIZATION");
  let agents = getEntityItems(state, "COMPANIES.NEWCLIENTCO.CATALOG.SALESAGENT");
  let isLoading_agent = state?.api["COMPANIES.NEWCLIENTCO.CATALOG.SALESAGENT"]?.status?.isFetching || false;

  return{
    contact_type,
    industry_type_catalog,
    primary_distribution_t1,
    secundary_distribution_t2,
    last_mile,
    challenges_concerns,
    digital_transformation,
    time_in_process,
    budget_invested,
    faculties_in_organization,
    isLoading,
    agents,
    isLoading_agent
  };
}

const mapDispatchToProps = (dispatch) => {

  dispatch(readEntities("COMPANIES.NEWCLIENTCO.CATALOG.CONTACTTYPE", {requiresAuth: false}));
  dispatch(readEntities("COMPANIES.NEWCLIENTCO.CATALOG.INDUSTRYTYPECATALOG", {requiresAuth: false}));
  dispatch(readEntities("COMPANIES.NEWCLIENTCO.CATALOG.PRIMARYDISTRIBUTIONT1", {requiresAuth: false}));
  dispatch(readEntities("COMPANIES.NEWCLIENTCO.CATALOG.SECUNDARYDISTRIBUTIONT2", {requiresAuth: false}));
  dispatch(readEntities("COMPANIES.NEWCLIENTCO.CATALOG.LASTMILE", {requiresAuth: false}));
  dispatch(readEntities("COMPANIES.NEWCLIENTCO.CATALOG.CHALLENGESCONCERNS", {requiresAuth: false}));
  dispatch(readEntities("COMPANIES.NEWCLIENTCO.CATALOG.DIGITALTRANSFORMATION", {requiresAuth: false}));
  dispatch(readEntities("COMPANIES.NEWCLIENTCO.CATALOG.TIMEINPROCESS", {requiresAuth: false}));
  dispatch(readEntities("COMPANIES.NEWCLIENTCO.CATALOG.BUDGETINVESTED", {requiresAuth: false}));
  dispatch(readEntities("COMPANIES.NEWCLIENTCO.CATALOG.FACULTIESINORGANIZATION", {requiresAuth: false}));

  return {
    cancelMultipleRequests: () => dispatch(cancelMultipleRequests()),
    createClient: (params, opt) => dispatch(createEntity("COMPANIES.NEWCLIENTCO", params, opt)),
    updateClient: (params, opt) => dispatch(partialEntity("COMPANIES.NEWCLIENTCO", params, opt)),
    getSalesAgent: (params, opt) => dispatch(readEntities("COMPANIES.NEWCLIENTCO.CATALOG.SALESAGENT", params, opt)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterNewClientView);

const ItemTypes = {
  CARD: 'card',
}

const Card = ({ id, component, index, moveCard, dropItems, isMobile }) => {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({

    accept: ItemTypes.CARD,
    drop(item, monitor) {
      dropItems();
    },
    hover(item, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      if (dragIndex === hoverIndex) {
        return
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      moveCard(dragIndex, hoverIndex)

      item.index = hoverIndex
    },
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
  })

  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.CARD, id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1

  drag(drop(ref))

  return (
    <div 
      ref={ref} 
      style={{
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#2f3b52',
        alignItems: 'center',
        marginBottom: 8,
        opacity
      }}
      data-handler-id={handlerId}
    >
      {component}
      {
        !isMobile &&
        <img
          alt=''
          src='/images/draggable-dots.png'
          style={{ cursor: 'move', height: 30, width: 30, filter: 'invert(60%)'}}/>
      }
    </div>
  )
}
