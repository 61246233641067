import React, { Component } from "react";
import Loader from '../icons/tat/loader';
import styled from "styled-components";

class OptionsList extends Component {

  getDataToShow = (data) => {

    if (typeof data === 'object') {
      return 'component' in data ? data['component'] : ''
    }

    return data;
  }

  getInfo = (data) => {

    if (typeof data === 'object') {
      return 'item' in data ? data['item'] : ''
    }

    return data;

  }

  buildList = () => {

    if (this.props.loading && this.props.visible) {
      return (
        <div
          className='option-list-component'
          style={{
            display: 'flex', 
            alignItems: 'center', 
            justifyContent: 'center',
            backgroundColor: 'white', 
            width: this.props.width || 50,
            position: 'absolute', 
            borderRadius: 5,
            paddingTop: 10,
            paddingBottom: 10
          }}
        >
          <Loader circleColor={'black'}/>
        </div>
      );

    } else if ((this.props.items || []).length > 3 && this.props.visible) {
      return (
        <div className='option-list-component' style={{backgroundColor: 'white', width: this.props.width || 50, position: 'absolute', borderRadius: 5, height: 150, overflowY: 'scroll'}}>
          {
            (this.props.items || []).map( (item, index) => {
              return(
                <div className='option-list-component' key={`option-list-component-row-${index}`}>
                  <OptionListComponent
                    className='option-list-component'
                    onClick={() => this.props.onClick(this.getInfo(item))}
                  >
                    {this.getDataToShow(item)}
                  </OptionListComponent>
                  <div style={{height: 0.5, backgroundColor: 'black', width: '100%'}}/>
                </div>
              );
            })
          }
        </div>
      );
    } else if ((this.props.items || []).length > 0 && this.props.visible) {
      return (
        <div className='option-list-component' style={{backgroundColor: 'white', width: this.props.width || 50, position: 'absolute', borderRadius: 5}}>
          {
            (this.props.items || []).map( (item, index) => {
              return(
                <div className='option-list-component' key={`option-list-component-row-${index}`}>
                  <OptionListComponent
                    className='option-list-component'
                    onClick={() => this.props.onClick(this.getInfo(item))}
                  >
                    {this.getDataToShow(item)}
                  </OptionListComponent>
                  <div style={{height: 0.5, backgroundColor: 'black', width: '100%'}}/>
                </div>
              );
            })
          }
        </div>
      );
    } else {
      return (<div/>);
    }

  }

  render() {
    return (
      this.buildList()
    );
  }
}
export default OptionsList;

export const OptionListComponent = styled.div`
  color: black; 
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 15px;
  justify-content: center;
  font-weight: normal;
  white-space: normal;
  text-align: center;
  cursor: pointer;
  padding-top: 10px;
  padding-bottom: 10px;

  &:hover {
    background-color: #e6f4ff;
  }
`;